import _ from "lodash"

export const getOrderListItems = state => _.get(state,['orderList','items'],null)
export const getOrderListLoading = state => _.get(state,['orderList','loading'],false)
export const getOrderListPagination = state => _.get(state,['orderList','pagination'],null)
export const getCreateOrderStatus = state => _.get(state,['createOrder','status'],false)
export const getCreateOrderLoading = state => _.get(state,['createOrder','loading'],false)

//order
export const getOrderItemStatusName = order => _.get(order,['status','name'],"")
export const getOrderItemRequestQty = order => _.get(order,['request_qty'],"")
export const getOrderItemReferenceNumber = order => _.get(order,['reference_number'],"")
export const getOrderItemAccountName = order => _.get(order,['account_name'],"")
export const getOrderItemCreatedDateRelative = order => _.get(order,['created_date','relative'],"")

// Subscription Order
export const getOrderPaymentDetailLoading = state => _.get(state, ["orderPaymentDetail", "loading"], false);

export const getOrderPaymentDetailStatus = state => _.get(state, ["orderPaymentDetail", "status"], false);

export const getOrderPaymentDetailItem = state => _.get(state, ["orderPaymentDetail", "items"], null);
export const getAgentSubscriptionPaymentURL = order => _.get(order,['payment'],"")


export const getOrderHistoryListLoading = state => _.get(state, ["orderHistoryList", "loading"], false);
export const getOrderHistoryListItems = state => _.get(state, ["orderHistoryList", "items"], null);
export const getOrderHistoryListPagination = state => _.get(state, ["orderHistoryList", "pagination"], null);

export const getOrderHistoryListReferNumber = orderHistoryList => _.get(orderHistoryList,['referenceNumber'],"")
export const getOrderHistoryListPackage = orderHistoryList => _.get(orderHistoryList,['items'],"")
export const getOrderHistoryListDatePurchased = orderHistoryList => _.get(orderHistoryList,['checkoutAt', 'text'],"")
export const getOrderHistoryListIssuedData = orderHistoryList => _.get(orderHistoryList,['issuedAt', 'text'],"")
export const getOrderHistoryListTotal= orderHistoryList => _.get(orderHistoryList,['payment','total','text'],"")
export const getOrderHistoryListStatus= orderHistoryList => _.get(orderHistoryList,['status','name'],"")
export const getOrderHistoryListStatusCode= orderHistoryList => _.get(orderHistoryList,['status','code'],"")
export const getOrderHistoryListGateway= orderHistoryList => _.get(orderHistoryList,['gateway'],"")
