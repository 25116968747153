import Image from "next/image";
import React, { Component } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import { Color, Color as Colors } from "../../../src/utils/Color";
import { withTheme, useTheme } from "styled-components";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import CustomFont from "../../../components/CustomFont";
import { maxDevice } from "../../../src/utils/breakpoints";
import Router from "next/router";
import * as selectors from "../../../src/selectors";
import * as globalActions from "../../../src/actions/global";
import { connect } from "dva";
import * as globalSelectors from "../../../src/selectors/global";
import * as newsSelectors from "../../../src/selectors/news";
import _ from "lodash";
import * as searchActions from "../../../src/actions/search";
import Constants from "../../../src/constants";
import Enviroment from "../../../src/utils/Environment";
import Helpers from "../../../src/utils/Helpers";
import { NextSeo } from "next-seo";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";

const PropertyToolContainer = styled.div``;
const PropertyToolTextContent = styled.div``;
const PropertyToolImageContainer = styled.div``;

const FooterIconContent = styled.div`
  margin: 10px 0;
  display: flex;
  font-size: 0.9rem;
  color: ${Colors.textWhite};

  &:hover {
    color: ${Colors.primaryColor};
    //transition: 200ms ease-in;
  }
`;

class FooterDivider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                display: { xs: "none", md: "block" }
              }}
            >
              <CustomFont
                size="small"
                styles={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  color: Colors.textWhite,
                  margin: "10px 0",
                  paddingRight: "30px"
                }}
              >
                {`Malaysia’s fastest growing and expanding property portal for users and real estate agents alike. 
                With PropertyGenie, discovering your ideal property - be it for`}{" "}
                <a
                  href={`${process.env.WEBSITE_URL}property-for-sale`}
                  style={{
                    // textDecorationLine: "underline",
                    color: "#6785E7",
                    cursor: "pointer",
                    fontWeight: "600"
                  }}
                >{`buying`}</a>
                {`,`}{" "}
                <a
                  href={`${process.env.WEBSITE_URL}property-for-rent`}
                  style={{
                    // textDecorationLine: "underline",
                    color: "#6785E7",
                    cursor: "pointer",
                    fontWeight: "600"
                  }}
                >{`renting`}</a>{" "}
                {`or investing in residential or commercial spaces across Malaysia.`}
              </CustomFont>
            </Box>
            <Box
              sx={{
                display: { xs: "block", md: "none" }
              }}
            >
              <CustomFont
                size="small"
                styles={{
                  textAlign: "justify",
                  lineHeight: "1.5",
                  color: Colors.textWhite,
                  margin: "10px 0"
                }}
              >
                {`Malaysia’s fastest growing and expanding property portal for users and real estate agents alike. 
                With PropertyGenie, discovering your ideal property - be it for`}{" "}
                <a
                  href={`${process.env.WEBSITE_URL}property-for-sale`}
                  style={{
                    // textDecorationLine: "underline",
                    color: "#6785E7",
                    cursor: "pointer",
                    fontWeight: "600"
                  }}
                >{`buying`}</a>
                {`,`}{" "}
                <a
                  href={`${process.env.WEBSITE_URL}property-for-rent`}
                  style={{
                    // textDecorationLine: "underline",
                    color: "#6785E7",
                    cursor: "pointer",
                    fontWeight: "600"
                  }}
                >{`renting`}</a>{" "}
                {`or investing in residential or commercial spaces across Malaysia.`}
              </CustomFont>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <FooterIconContent style={{ alignItems: "center" }}>
              <LocalPhoneOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />
              <a
                href={`tel:+60356260022`}
                style={{
                  // textDecorationLine: "underline",
                  cursor: "pointer"
                }}
              >{`+603 5626 0022`}</a>
            </FooterIconContent>
            <FooterIconContent style={{ alignItems: "center" }}>
              <WhatsAppIcon sx={{ marginRight: 2, fontSize: 20 }} />
              <a
                href={`https://api.whatsapp.com/send/?phone=60196932255&text&type=phone_number&app_absent=0`}
                target={"_blank"}
                rel="noopener noreferrer"
                style={{
                  // textDecorationLine: "underline",
                  cursor: "pointer"
                }}
              >{`+6019 693 2255`}</a>
            </FooterIconContent>
            <FooterIconContent>
              <RoomOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />
              <a
                href={`https://maps.app.goo.gl/tV8qtndtmFvc9AfN8`}
                style={{
                  // textDecorationLine: "underline",
                  cursor: "pointer"
                }}
              >{`D-6-1, Menara Suezcap 1,  No.2, Jalan Kerinchi, 
                    Gerbang Kerinchi Lestari, 59200 Kuala Lumpur W.P`}</a>
            </FooterIconContent>
          </Grid>
          <Grid item md={3} xs={12}>
            <FooterIconContent style={{ alignItems: "center" }}>
              <EmailOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />
              <a
                href={`mailto:info@propertygenie.com.my`}
                style={{
                  // textDecorationLine: "underline",
                  cursor: "pointer"
                }}
              >{`info@propertygenie.com.my`}</a>
            </FooterIconContent>
            <FooterIconContent style={{ alignItems: "center" }}>
              <EmailOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />
              <a
                href={`mailto:advertising@propertygenie.com.my`}
                style={{
                  // textDecorationLine: "underline",
                  cursor: "pointer"
                }}
              >{`advertising@propertygenie.com.my`}</a>
            </FooterIconContent>
            <FooterIconContent style={{ alignItems: "center" }}>
              <EmailOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />
              <a
                href={`mailto:media@propertygenie.com.my`}
                style={{
                  // textDecorationLine: "underline",
                  cursor: "pointer"
                }}
              >{`media@propertygenie.com.my`}</a>
            </FooterIconContent>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FooterDivider)
);
