// import React,{ useState } from "react";
// import Box from "@mui/material/Box";
// import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";
// import { Column, FooterLink, Heading } from "./FooterStyles";
// import Typography from "@mui/material/Typography";
// import Image from "next/image";
// import Divider from "@mui/material/Divider";
// import Row from "react-bootstrap/Row";
// import Stack from "@mui/material/Stack";
// import styled from "styled-components";
// import { withTheme } from "styled-components";
// import { maxDevice } from "../../src/utils/breakpoints";
// import { Color } from "../../src/utils/Color";
// import _ from "lodash";
// import Router from "next/router";
// import * as selectors from "../../src/selectors";
// import * as searchActions from "../../src/actions/search";
// import { connect } from "dva";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
// import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
// import TabsListUnstyled from "@mui/base/TabsListUnstyled";
// import TabsUnstyled from "@mui/base/TabsUnstyled";
// import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
// import { buttonUnstyledClasses } from "@mui/base";
// import {
//   JohorCoordinateData,
//   KedahCoordinateData,
//   KelantanCoordinateData,
//   MelakaCoordinateData,
//   PerakCoordinateData,
//   PerlisCoordinateData,
//   SabahCoordinateData,
//   SelangorCoordinateData,
//   KualaLumpurCoordinateData,
//   NegeriSembilanCoordinateData,
//   LabuanCoordinateData,
//   PahangCoordinateData,
//   PenangCoordinateData,
//   PutrajayaCoordinateData,
//   SarawakCoordinateData,
//   TerengganuCoordinateData,
//   PetalingJayaCoordinateData,
//   JohorBahruCoordinateData,
//   GeorgetownCoordinateData,
//   TamanPelangiCoordinateData,
//   SubangJayaCoordinateData,
//   MontKiaraCoordinateData,
//   IskandarPuteriCoordinateData,
//   UptownCoordinateData,
//   BukitJalilCoordinateData,
//   BukitBintangCoordinateData,
//   PuchongCoordinateData,
//   AmpangCoordinateData,
//   BangsarCoordinateData
// } from "../../src/utils/CoordinateData";
// import Button from '@material-ui/core/Button'
// import Hidden from "@material-ui/core/Hidden";
// import FooterAccordionMobile from "../FooterAccordionMobile"
// import FooterBottomSection from "../FooterBottomSection"
//
// const Tab = styled(TabUnstyled)`
//   color: #2c2c2c;
//   cursor: pointer;
//   font-size: 1rem !important;
//   font-weight: normal;
//   background-color: transparent;
//   line-height: 25px;
//   padding: 5px 0 !important;
//   border: none;
//   border-radius: 10px 0 0 0;
//   display: flex;
//   justify-content: center;
//   margin-right: 30px !important;
//   align-items: center;
//   border-bottom: 2px solid transparent;
//
//   &:hover {
//     background-color: transparent;
//     color: #3462f4;
//   }
//
//   &:focus {
//     color: #3462f4;
//     outline-offset: 2px;
//   }
//
//   &.${tabUnstyledClasses.selected} {
//     background-color: transparent;
//     color: #3462f4;
//     font-weight: 600 !important;
//     border-bottom: 2px solid #3462f4;
//   }
//
//   &.${buttonUnstyledClasses.disabled} {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// `;
//
// const TabPanel = styled(TabPanelUnstyled)`
//   font-size: 0.875rem;
//   &.MuiTab-root {
//     padding: 5px 0px;
//   }
// `;
//
// const TabsList = styled(TabsListUnstyled)`
//   background-color: transparent;
//   display: flex;
//   align-items: flex-start;
//   justify-content: flex-start;
//   flex-wrap: wrap;
//   //border-bottom: 1px solid #dddddd;
//   &.MuiTab-root {
//     padding: 5px 0px;
//   }
// `;
// const ImageContainer = styled.div`
//   width: 75%;
//   height: 100px;
//   position: relative;
// `;
//
// const CustomFont = styled(Typography)`
//   margin-top: 1rem;
//   @media ${maxDevice.sm} {
//     text-align: center;
//   }
// `;
//
// const CustomFontState = styled(Typography)`
//   margin-top: 1rem;
//   font-size: 0.9rem !important;
//   @media ${maxDevice.sm} {
//     text-align: left;
//   }
// `;
//
// const HeadingStyle = styled.p`
//   font-size: 1.3rem;
//   color: #2c2c2c;
//   margin-bottom: 30px;
//   font-weight: 600;
//
//   @media ${maxDevice.sm} {
//     text-align: center;
//   }
// `;
//
// const FooterLinkStyle = styled.a`
//   color: #2c2c2c;
//   margin-bottom: 20px;
//   font-size: 1rem;
//   text-decoration: none;
//
//   &:hover {
//     color: #3462f4;
//     transition: 200ms ease-in;
//   }
//
//   @media ${maxDevice.sm} {
//     text-align: center;
//   }
// `;
//
// const CopyRightText = styled(Typography)`
//   color: #2c2c2c;
//
//   @media ${maxDevice.sm} {
//     text-align: center;
//     margin-top: 20px;
//   }
// `;
//
// const StackContainerFooter = styled(Stack)`
//   justify-content: flex-end;
//
//   @media ${maxDevice.sm} {
//     justify-content: center;
//     margin-top: 20px;
//   }
// `;
//
// const FooterGridStart = styled(Grid)`
//   padding-top: 20px;
//
//   @media ${maxDevice.sm} {
//     margin-top: 20px !important;
//   }
// `;
//
// const FooterGridStateStart = styled(Grid)`
//   padding-top: 0px;
//
//   @media ${maxDevice.sm} {
//     margin-top: 5px !important;
//   }
// `;
//
// const FooterStateContainer = styled.div`
//   color: #2c2c2c;
//   margin-top: 20px;
//   @media ${maxDevice.sm} {
//     margin-top: 5px !important;
//   }
//   &:hover {
//     cursor: pointer;
//     color: ${Color.primaryColor};
//     text-decoration: underline;
//   }
// `;
// const onClickAboutUs = () => {
//   Router.push("/about-us");
// };
//
//
// const Footer = props => {
//   const { theme } = props;
//   // const [viewMore, setViewMore] = useState(false);
//   // const [viewMore2, setViewMore2] = useState(false);
//   // const [viewMore3, setViewMore3] = useState(false);
//   // const [viewMore4, setViewMore4] = useState(false);
//   //
//   // const toggleViewMore = () => {
//   //   setViewMore(!viewMore);
//   // };
//   // const toggleViewMore2 = () => {
//   //   setViewMore2(!viewMore2);
//   // };
//   // const toggleViewMore3 = () => {
//   //   setViewMore3(!viewMore3);
//   // };
//   // const toggleViewMore4 = () => {
//   //   setViewMore4(!viewMore4);
//   // };
//   const onClickPropertyForSale = e => {
//     const { clearFilterData } = props;
//     e.preventDefault();
//     clearFilterData && clearFilterData();
//
//     Router.push({
//       pathname: "/property-for-sale"
//     });
//   };
//
//
//   const onClickPropertyForRent = e => {
//     const { clearFilterData } = props;
//     e.preventDefault();
//     clearFilterData && clearFilterData();
//
//     Router.push({
//       pathname: "/property-for-rent"
//     });
//   };
//
//   const onClickPropertyRentState = (e, coordinateData) => {
//     const { setCoordinateData } = props;
//
//     e.preventDefault();
//
//     setCoordinateData && setCoordinateData(coordinateData);
//     Router.push({
//       pathname: "/property-for-rent"
//     });
//   };
//
//   const onClickPropertySaleState = (e, coordinateData) => {
//     const { setCoordinateData } = props;
//
//     e.preventDefault();
//
//     setCoordinateData && setCoordinateData(coordinateData);
//
//     Router.push({
//       pathname: "/property-for-sale"
//     });
//   };
//
//   return (
//     <Box style={{ marginTop: "4rem" }}>
//       <Container>
//         <Hidden smDown>
//         <TabsUnstyled
//           style={{
//             // paddingTop: "2rem",
//             // borderTop: `1px solid ${Color.borderColor}`,
//             marginBottom: "2rem",
//             flexDirection: "column"
//           }}
//           //   onChange={this.handleChangeTab}
//           defaultValue={0}
//         >
//           <TabsList>
//             <Tab>Top Searched Property For Sale</Tab>
//             <Tab>Top Searched Property For Rent</Tab>
//             <Tab>Malaysia Property For Sale</Tab>
//             <Tab>Malaysia Property For Rent</Tab>
//           </TabsList>
//           <TabPanel style={{ background: "white" }} value={0} index={0}>
//             <FooterGridStateStart container spacing={2}>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, KualaLumpurCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Kuala Lumpur
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, PetalingJayaCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Petaling Jaya
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, JohorBahruCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Johor Bahru
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, SubangJayaCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Subang Jaya
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 {/*{viewMore && (*/}
//                 {/*  <FooterStateContainer onClick={e => onClickPropertyRentState(e, SabahCoordinateData)}>*/}
//                 {/*    <CustomFontState styles={{ color: "inherit" }}>*/}
//                 {/*      Property For Sale in Sabah*/}
//                 {/*    </CustomFontState>*/}
//                 {/*  </FooterStateContainer>*/}
//                 {/*)}*/}
//                 {/*<div style={{ textAlign: "left", marginTop: "2rem", marginBottom: "2rem" }}>*/}
//                 {/*  <CustomFont onClick={toggleViewMore} styles={{ color: Color.primaryColor, cursor: "pointer" }}> {viewMore ? "View Less" : "View More"}</CustomFont>*/}
//                 {/*</div>*/}
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, MontKiaraCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Mont Kiara
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer
//                   onClick={e => onClickPropertySaleState(e, IskandarPuteriCoordinateData)}
//                 >
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Iskandar Puteri
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, UptownCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Uptown
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, BukitJalilCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Bukit Jalil
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 {/*{viewMore2 && (*/}
//                 {/*  <FooterStateContainer onClick={e => onClickPropertyRentState(e, SabahCoordinateData)}>*/}
//                 {/*    <CustomFontState styles={{ color: "inherit" }}>*/}
//                 {/*      Property For Sale in Sabah*/}
//                 {/*    </CustomFontState>*/}
//                 {/*  </FooterStateContainer>*/}
//                 {/*)}*/}
//                 {/*<div style={{ textAlign: "center", marginTop: "2rem", marginBottom: "2rem" }}>*/}
//                 {/*  <Button*/}
//                 {/*    onClick={toggleViewMore2}*/}
//                 {/*    variant="outlined"*/}
//                 {/*    style={{*/}
//                 {/*      fontWeight: "600",*/}
//                 {/*      borderRadius: "10px",*/}
//                 {/*      fontSize: "1.1rem"*/}
//                 {/*    }}*/}
//                 {/*    color="primary"*/}
//                 {/*  >*/}
//                 {/*    {viewMore2 ? "View Less" : "View More"}*/}
//                 {/*  </Button>*/}
//                 {/*</div>*/}
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, BukitBintangCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Bukit Bintang
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, JohorBahruCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Taman Pelangi
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, GeorgetownCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Georgetown
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, PuchongCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Puchong
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 {/*{viewMore3 && (*/}
//                 {/*  <FooterStateContainer onClick={e => onClickPropertyRentState(e, SabahCoordinateData)}>*/}
//                 {/*    <CustomFontState styles={{ color: "inherit" }}>*/}
//                 {/*      Property For Sale in Sabah*/}
//                 {/*    </CustomFontState>*/}
//                 {/*  </FooterStateContainer>*/}
//                 {/*)}*/}
//                 {/*<div style={{ textAlign: "center", marginTop: "2rem", marginBottom: "2rem" }}>*/}
//                 {/*  <Button*/}
//                 {/*    onClick={toggleViewMore3}*/}
//                 {/*    variant="outlined"*/}
//                 {/*    style={{*/}
//                 {/*      fontWeight: "600",*/}
//                 {/*      borderRadius: "10px",*/}
//                 {/*      fontSize: "1.1rem"*/}
//                 {/*    }}*/}
//                 {/*    color="primary"*/}
//                 {/*  >*/}
//                 {/*    {viewMore3 ? "View Less" : "View More"}*/}
//                 {/*  </Button>*/}
//                 {/*</div>*/}
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, AmpangCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Ampang
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, BangsarCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Bangsar
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 {/*{viewMore4 && (*/}
//                 {/*  <FooterStateContainer onClick={e => onClickPropertyRentState(e, SabahCoordinateData)}>*/}
//                 {/*    <CustomFontState styles={{ color: "inherit" }}>*/}
//                 {/*      Property For Sale in Sabah*/}
//                 {/*    </CustomFontState>*/}
//                 {/*  </FooterStateContainer>*/}
//                 {/*)}*/}
//                 {/*<div style={{ textAlign: "center", marginTop: "2rem", marginBottom: "2rem" }}>*/}
//                 {/*  <Button*/}
//                 {/*    onClick={toggleViewMore4}*/}
//                 {/*    variant="outlined"*/}
//                 {/*    style={{*/}
//                 {/*      fontWeight: "600",*/}
//                 {/*      borderRadius: "10px",*/}
//                 {/*      fontSize: "1.1rem"*/}
//                 {/*    }}*/}
//                 {/*    color="primary"*/}
//                 {/*  >*/}
//                 {/*    {viewMore4 ? "View Less" : "View More"}*/}
//                 {/*  </Button>*/}
//                 {/*</div>*/}
//               </FooterGridStateStart>
//             </FooterGridStateStart>
//           </TabPanel>
//           <TabPanel style={{ background: "white" }} value={1} index={1}>
//             <FooterGridStateStart container spacing={2}>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, KualaLumpurCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Kuala Lumpur
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, PetalingJayaCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Petaling Jaya
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, JohorBahruCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Johor Bahru
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, SubangJayaCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Subang Jaya
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, MontKiaraCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Mont Kiara
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer
//                   onClick={e => onClickPropertyRentState(e, IskandarPuteriCoordinateData)}
//                 >
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Iskandar Puteri
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, UptownCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Uptown
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, BukitJalilCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Bukit Jalil
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, BukitBintangCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Bukit Bintang
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, JohorBahruCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Taman Pelangi
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, GeorgetownCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Sale in Georgetown
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, PuchongCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Puchong
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, AmpangCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Ampang
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, BangsarCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Property For Rent in Bangsar
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//             </FooterGridStateStart>
//           </TabPanel>
//           <TabPanel style={{ background: "white" }} value={2} index={2}>
//             <FooterGridStateStart container spacing={2}>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, SelangorCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Selangor
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, PerakCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Perak
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, SabahCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Sabah
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, KelantanCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Kelantan
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, JohorCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Johor
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, MelakaCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Melaka
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, KedahCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Kedah
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, PerlisCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Perlis
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, PenangCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Penang
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, PahangCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Pahang
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, PutrajayaCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Putrajaya
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, LabuanCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Labuan
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, KualaLumpurCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Kuala Lumpur
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer
//                   onClick={e => onClickPropertySaleState(e, NegeriSembilanCoordinateData)}
//                 >
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Negeri Sembilan
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, SarawakCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Sarawak
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertySaleState(e, TerengganuCoordinateData)}>
//                   <CustomFontState size="small" styles={{ color: "inherit" }}>
//                     Properties For Sale In Terengganu
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//             </FooterGridStateStart>
//           </TabPanel>
//           <TabPanel style={{ background: "white" }} value={3} index={3}>
//             <FooterGridStateStart container spacing={2}>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, SelangorCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Selangor
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, PerakCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Perak
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, SabahCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Sabah
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, KelantanCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Kelantan
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, JohorCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Johor
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, MelakaCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Melaka
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, KedahCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Kedah
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, PerlisCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Perlis
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, PenangCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Penang
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, PahangCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Pahang
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, PutrajayaCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Putrajaya
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, LabuanCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Labuan
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//               <FooterGridStateStart item sm={6} md={3} xs={12}>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, KualaLumpurCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Kuala Lumpur
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer
//                   onClick={e => onClickPropertyRentState(e, NegeriSembilanCoordinateData)}
//                 >
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Negeri Sembilan
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, SarawakCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Sarawak
//                   </CustomFontState>
//                 </FooterStateContainer>
//                 <FooterStateContainer onClick={e => onClickPropertyRentState(e, TerengganuCoordinateData)}>
//                   <CustomFontState styles={{ color: "inherit" }}>
//                     Properties For Rent In Terengganu
//                   </CustomFontState>
//                 </FooterStateContainer>
//               </FooterGridStateStart>
//             </FooterGridStateStart>
//           </TabPanel>
//         </TabsUnstyled>
//         </Hidden>
//         <Hidden mdUp>
//           <FooterAccordionMobile />
//         </Hidden>
//       </Container>
//       {/*===============Lowest Footer Section=========================*/}
//       <Container>
//         <FooterBottomSection />
//         {/*<FooterGridStart*/}
//         {/*  container*/}
//         {/*  spacing={2}*/}
//         {/*  style={{ borderTop: `1px solid ${Color.borderColor}`, marginTop: 0 }}*/}
//         {/*>*/}
//         {/*  <FooterGridStart item sm={5} md={5} xs={12}>*/}
//         {/*    <Column>*/}
//         {/*      <ImageContainer>*/}
//         {/*        <a*/}
//         {/*          href={`${process.env.WEBSITE_URL}`}*/}
//         {/*        >*/}
//         {/*        <Image src="/footer-logo.png" layout="fill" objectFit="contain" alt="Property Genie"/>*/}
//         {/*        </a>*/}
//         {/*      </ImageContainer>*/}
//         {/*      <CustomFont style={{ marginTop: "2rem", fontSize: "0.9rem" }}>*/}
//         {/*        Everything property And Real Estate in Malaysia. Find your dream property now*/}
//         {/*      </CustomFont>*/}
//         {/*    </Column>*/}
//         {/*  </FooterGridStart>*/}
//         {/*  <Grid item sm={2} md={2} xs={12}>*/}
//         {/*    <Column>*/}
//         {/*      <HeadingStyle>Company</HeadingStyle>*/}
//         {/*      <FooterLinkStyle onClick={onClickAboutUs} href="/about-us">*/}
//         {/*        About Us*/}
//         {/*      </FooterLinkStyle>*/}
//         {/*      <FooterLinkStyle href="/contact-us">Contact Us</FooterLinkStyle>*/}
//         {/*      <FooterLinkStyle href="/career">Careers</FooterLinkStyle>*/}
//         {/*    </Column>*/}
//         {/*  </Grid>*/}
//         {/*  <Grid item sm={2} md={2} xs={12}>*/}
//         {/*    <Column>*/}
//         {/*      <HeadingStyle>Help Center</HeadingStyle>*/}
//         {/*      /!*<FooterLinkStyle href="#">FAQ</FooterLinkStyle>*!/*/}
//         {/*      <FooterLinkStyle href="/term-of-use" target="_blank">Terms Of Use</FooterLinkStyle>*/}
//         {/*      <FooterLinkStyle href="/privacy-policy" target="_blank">Privacy Policy</FooterLinkStyle>*/}
//         {/*      <FooterLinkStyle href="/refund-policy" target="_blank">Refund Policy </FooterLinkStyle>*/}
//         {/*    </Column>*/}
//         {/*  </Grid>*/}
//         {/*  <Grid item sm={3} md={3} xs={12}>*/}
//         {/*    <Column>*/}
//         {/*      <HeadingStyle>Tenants & Landlords</HeadingStyle>*/}
//         {/*      <FooterLinkStyle href="/property-for-sale" onClick={e => onClickPropertyForSale(e)}>*/}
//         {/*        Buy*/}
//         {/*      </FooterLinkStyle>*/}
//         {/*      <FooterLinkStyle href="/property-for-rent" onClick={e => onClickPropertyForRent(e)}>*/}
//         {/*        Rent*/}
//         {/*      </FooterLinkStyle>*/}
//         {/*      <FooterLinkStyle href="/project">Projects</FooterLinkStyle>*/}
//         {/*    </Column>*/}
//         {/*  </Grid>*/}
//         {/*</FooterGridStart>*/}
//         {/*<Divider*/}
//         {/*  style={{*/}
//         {/*    backgroundColor: "#2c2c2c",*/}
//         {/*    height: "2px",*/}
//         {/*    marginTop: "2rem",*/}
//         {/*    marginBottom: "1rem"*/}
//         {/*  }}*/}
//         {/*/>*/}
//         {/*<Grid container style={{ marginBottom: "1rem" }}>*/}
//         {/*  <Grid item md={8}>*/}
//         {/*    <CopyRightText fontSize="0.9rem">*/}
//         {/*      © 2023 Property Genie Sdn Bhd. All rights reserved.*/}
//         {/*    </CopyRightText>*/}
//         {/*  </Grid>*/}
//         {/*  <Grid item md={4} style={{ width: "100%" }}>*/}
//         {/*    <StackContainerFooter direction="row" spacing={2}>*/}
//         {/*      <a href="https://www.facebook.com/propertygenie.com.my" target="_blank" rel="noreferrer">*/}
//         {/*        <FacebookIcon />*/}
//         {/*        /!*<i*!/*/}
//         {/*        /!*  className="fa fa-facebook"*!/*/}
//         {/*        /!*  aria-hidden="true"*!/*/}
//         {/*        /!*  style={{ fontSize: "18px" }}*!/*/}
//         {/*        />*/}
//         {/*      </a>*/}
//         {/*      /!*<a href="https://www.linkedin.com" target="_blank" rel="noreferrer">*!/*/}
//         {/*      /!*  <LinkedInIcon />*!/*/}
//         {/*      /!*  <i*!/*/}
//         {/*      /!*      className="fa fa-linkedin"*!/*/}
//         {/*      /!*      aria-hidden="true"*!/*/}
//         {/*      /!*      style={{ fontSize: "18px" }}*!/*/}
//         {/*      /!*  />*!/*/}
//         {/*      /!*</a>*!/*/}
//         {/*      /!*<a href="https://www.twitter.com" target="_blank" rel="noreferrer">*!/*/}
//         {/*      /!*  <TwitterIcon />*!/*/}
//         {/*      /!*  <i*!/*/}
//         {/*      /!*      className="fa fa-twitter"*!/*/}
//         {/*      /!*      aria-hidden="true"*!/*/}
//         {/*      /!*      style={{ fontSize: "18px" }}*!/*/}
//         {/*      /!*  />*!/*/}
//         {/*      /!*</a>*!/*/}
//         {/*      <a href="https://www.instagram.com/propertygenie.com.my/" target="_blank" rel="noreferrer">*/}
//         {/*        <InstagramIcon />*/}
//         {/*        /!*<i*!/*/}
//         {/*        /!*  className="fa fa-instagram"*!/*/}
//         {/*        /!*  aria-hidden="true"*!/*/}
//         {/*        /!*  style={{ fontSize: "18px" }}*!/*/}
//         {/*        />*/}
//         {/*      </a>*/}
//         {/*      /!*<a href="https://www.youtube.com" target="_blank" rel="noreferrer">*!/*/}
//         {/*      /!*  <YouTubeIcon />*!/*/}
//         {/*      /!*  <i*!/*/}
//         {/*      /!*    className="fa fa-youtube-play"*!/*/}
//         {/*      /!*    aria-hidden="true"*!/*/}
//         {/*      /!*    style={{ fontSize: "18px" }}*!/*/}
//         {/*      /!*  />*!/*/}
//         {/*      /!*</a>*!/*/}
//         {/*    </StackContainerFooter>*/}
//         {/*  </Grid>*/}
//         {/*</Grid>*/}
//       </Container>
//     </Box>
//   );
// };
//
// Footer.defaultProps = {
//   theme: {
//     main: "#2c2c2c"
//   }
// };
//
// function mapStateToProps(state) {
//   return {
//     authToken: selectors.getAuthToken(state)
//   };
// }
//
// function mapDispatchToProps(dispatch) {
//   return {
//     clearFilterData: () => dispatch(searchActions.clearFilterData()),
//     setCoordinateData: coordinate => dispatch(searchActions.setCoordinateData(coordinate))
//   };
// }
//
// export default withTheme(
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(Footer)
// );

import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Color, Color as Colors } from "../../src/utils/Color";
import styled, { withTheme, useTheme } from "styled-components";
import { connect } from "dva";
import _ from "lodash";
import Router from "next/router";
import { NextSeo } from "next-seo";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomFont from "../../components/CustomFont";
import Divider from "@material-ui/core/Divider";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { maxDevice } from "../../src/utils/breakpoints";
import Box from "@mui/material/Box";
import {
  JohorCoordinateData,
  KedahCoordinateData,
  KelantanCoordinateData,
  MelakaCoordinateData,
  PerakCoordinateData,
  PerlisCoordinateData,
  SabahCoordinateData,
  SelangorCoordinateData,
  KualaLumpurCoordinateData,
  NegeriSembilanCoordinateData,
  LabuanCoordinateData,
  PahangCoordinateData,
  PenangCoordinateData,
  PutrajayaCoordinateData,
  SarawakCoordinateData,
  TerengganuCoordinateData,
  PetalingJayaCoordinateData,
  JohorBahruCoordinateData,
  GeorgetownCoordinateData,
  TamanPelangiCoordinateData,
  SubangJayaCoordinateData,
  MontKiaraCoordinateData,
  IskandarPuteriCoordinateData,
  UptownCoordinateData,
  BukitJalilCoordinateData,
  BukitBintangCoordinateData,
  PuchongCoordinateData,
  AmpangCoordinateData,
  BangsarCoordinateData
} from "../../src/utils/CoordinateData";
import * as selectors from "../../src/selectors";
import * as searchActions from "../../src/actions/search";
import Hidden from "@material-ui/core/Hidden";
import FooterAccordionMobile from "../FooterAccordionMobile";
import FooterBottomSection from "../FooterBottomSection";
import FooterDivider from "../../components/Footer/FooterDivider";
import FooterLocationDesktop from "../../components/FooterLocationDesktop";
import FooterCopyRight from "../../components/FooterCopyRight";

const FooterStateContainer = styled.div`
  font-size: 0.9rem;
  margin: 5px 0;

  &:hover {
    cursor: pointer;
    color: ${Color.primaryColor};
    text-decoration: underline;
  }
`;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMore: false,
      viewMore2: false,
      viewMore3: false,
      viewMore4: false
    };
  }

  // onClickPropertyForSale = e => {
  //    const { clearFilterData } = props;
  //    e.preventDefault();
  //    clearFilterData && clearFilterData();
  //
  //    Router.push({
  //      pathname: "/property-for-sale"
  //    });
  //  };
  //
  //
  //  onClickPropertyForRent = e => {
  //    const { clearFilterData } = props;
  //    e.preventDefault();
  //    clearFilterData && clearFilterData();
  //
  //    Router.push({
  //      pathname: "/property-for-rent"
  //    });
  //  };

  onClickPropertyRentState = (e, coordinateData) => {
    const { setCoordinateData } = this.props;

    e.preventDefault();

    setCoordinateData && setCoordinateData(coordinateData);
    Router.push({
      pathname: "/property-for-rent"
    });
  };

  onClickPropertySaleState = (e, coordinateData) => {
    const { setCoordinateData } = this.props;

    e.preventDefault();

    setCoordinateData && setCoordinateData(coordinateData);

    Router.push({
      pathname: "/property-for-sale"
    });
    console.log(coordinateData);
  };

  onClickViewMore = () => {
    this.setState({
      viewMore: !this.state.viewMore
    });
  };
  onClickViewMore2 = () => {
    this.setState({
      viewMore2: !this.state.viewMore2
    });
  };
  onClickViewMore3 = () => {
    this.setState({
      viewMore3: !this.state.viewMore3
    });
  };
  onClickViewMore4 = () => {
    this.setState({
      viewMore4: !this.state.viewMore4
    });
  };

  render() {
    const { viewMore, viewMore2, viewMore3, viewMore4 } = this.state;
    return (
      <Box sx={{ borderTop: "1px solid rgb(221, 221, 221)" }}>
        {/*=====For Footer Divider Section=======*/}
        <Box sx={{ background: "#142048" }}>
          <Container>
            <FooterDivider />
          </Container>
        </Box>
        {/*======End For Footer Divider Section====*/}
        <Container>
          <Box
            sx={{
              display: { xs: "block", md: "none" }
            }}
          >
            <FooterAccordionMobile />
          </Box>
        </Container>
        {/*===============Lowest Footer Section=========================*/}
        <Container>
          <FooterBottomSection />
          <Box
            sx={{
              display: { xs: "none", md: "block" }
            }}
          >
            <FooterLocationDesktop />
          </Box>
        </Container>
        {/*===============CopyRight Section=========================*/}
        <Container>
          <FooterCopyRight />
        </Container>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: selectors.getAuthToken(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearFilterData: () => dispatch(searchActions.clearFilterData()),
    setCoordinateData: coordinate =>
      dispatch(searchActions.setCoordinateData(coordinate))
  };
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Footer)
);
