import _ from "lodash";

export const getTransactionDataListingItems = (state) =>
    _.get(state, ["transactionDataListing", "items"], null);

// export const getTransactionCommonData = state => _.get(state, ["items"], null);
export const getTransactionCommonDataLoading = state => _.get(state, ["transactionCommonDataListing", "loading"], false);
export const getTransactionSuggestData = state => _.get(state, ["transactionSearchData", "items"], null);
export const getTransactionSuggestDataLoading = state => _.get(state, ["transactionSearchData", "loading"], null);


//filterCommonData
export const getTransactionCommonData = state => _.get(state, ["transactionCommonDataListing", "items", "type", "items"], null);
export const getTransactionCommonTypeResidentialData = state => _.get(state, ["transactionCommonDataListing", "items", "category", "residential", "items"], null);
export const getTransactionCommonTypeCommericalData = state => _.get(state, ["transactionCommonDataListing", "items", "category", "commercial", "items"], null);
export const getTransactionCommonTypeIndustrialData = state => _.get(state, ["transactionCommonDataListing", "items", "category", "industrial", "items"], null);
export const getTransactionCommonTenureData = state => _.get(state, ["transactionCommonDataListing", "items", "tenure", "items"], null);
export const getCommonCategoryText = filterData => _.get(filterData, ["text"], "");
export const getCommonCategoryValue = filterData => _.get(filterData, ["value"], "");
export const getCommonCategoryTypeText = filterData => _.get(filterData, ["text"], "");
export const getCommonCategoryTypeValue = filterData => _.get(filterData, ["value"], "");

//filter place suggestion
export const getTransactionSuggestStateText = filterData => _.get(filterData, ["state", "text"], "");
export const getTransactionSuggestStateOutter = filterData => _.get(filterData, ["state"], "");
export const getTransactionSuggestStateValue = filterData => _.get(filterData, ["state", "value"], "");
export const getTransactionSuggestCityText = filterData => _.get(filterData, ["city", "text"], "");
export const getTransactionSuggestCityValue = filterData => _.get(filterData, ["city", "value"], "");
export const getTransactionSuggestSchemeText = filterData => _.get(filterData, ["schemeName", "text"], "");
export const getTransactionSuggestSchemeValue = filterData => _.get(filterData, ["schemeName", "value"], "");

//pagination
export const getPageListingLoading = state =>
    _.get(state, ["pageListing", "loading"], false);
export const getPageListingItems = state =>
    _.get(state, ["pageListing", "items"], null);
export const getPageListingPagination = state =>
    _.get(state, ["pageListing", "pagination"], null);