import _ from "lodash";

export const getSearchListingLoading = state =>
  _.get(state, ["searchListing", "loading"], false);
export const getSearchListingItems = (state, route) =>
  _.get(state, ["searchListing", route, "items"], null);
export const getSearchListingPagination = (state, route) =>
  _.get(state, ["searchListing", route, "pagination"], null);
export const getCountListingPagination = (state, route) =>
    _.get(state, ["totalCount"], null);
export const getSearchFilterData = (state,route) => _.get(state,['filterData',route],null)
export const getSearchSortData = (state,route)=>_.get(state,['sortData',route],"-1")
export const getSearchSuggestionLoading = state => _.get(state,['searchSuggestion','loading'],false)
export const getSearchSuggestionItems = (state,route) => _.get(state,['searchSuggestion',route],[])
export const getSearchCoordinateData = state => _.get(state,['coordinate'],null)
