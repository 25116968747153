import _ from "lodash"

export const getNewsListItems = state => _.get(state,['newsList','items'],null)
export const getNewsListLoading = state =>  _.get(state,['newsList','loading'],false)
export const getNewsListPagination = state => _.get(state,['newsList','pagination'],null)

//news

export const getNewsDetailID = news => _.get(news,['id'],null)
export const getNewsDetailTitle = news => _.get(news,['title'],'')
export const getNewsDetailBrief = news => _.get(news,['brief'],'')
export const getNewsDetailImageMediumSrc = news => _.get(news,['image','medium','src'],null)
export const getNewsDetailImageHugeSrc = news => _.get(news,['image','huge','src'],null)
export const getNewsDetailPublistedAtRelative =  news => _.get(news,['publishedAt','relative'],"")
export const getNewsDetailPublistedAtText = news =>_.get(news,['publishedAt','text'],"")
export const getNewsDetailTagItems = news => _.get(news,['tag','items'],[])
export const getNewsDetailContent = news => _.get(news,['content'],'')
export const getNewsDetailSlug = news => _.get(news,['slug'],"")

export const getNewsDetailAuthor = news => _.get(news,['author'],"")
export const getNewsDetailCategory = news => _.get(news,['category', 'name'],"")

export const getNewsDetailRelated = news => _.get(news,['related'],"")
export const getNewsDetailTag = news => _.get(news,['tag'],"")
