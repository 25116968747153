import _ from "lodash";

export const getBlogLoading = state => _.get(state, ["loading"], false);
export const getBlogDetailData = state => _.get(state, ["blogDetail"], null);
export const getBlogDataItems = state => _.get(state, ["blogData", "items"], null);
export const getBlogDataMeta = state => _.get(state, ["blogData", "_meta"], null);
export const getBlogDataCategory = state => _.get(state, ["blogData", "category"], null);
export const getBlogDataTag = state => _.get(state, ["blogData", "tag"], null);
export const getBlogDataAuthor = state => _.get(state, ["blogData", "author"], null);

//insider-guide detail
export const getBlogID = blog => _.get(blog, ["id"], null);
export const getBlogCategoryName = blog => _.get(blog, ["category", "name"], "");
export const getBlogCategoryID = blog => _.get(blog, ["category", "id"], "");
export const getBlogTitle = blog => _.get(blog, ["title"], "");
export const getBlogDetailBrief = blog => _.get(blog,['brief'],'')
export const getBlogDetailImageMediumSrc = blog => _.get(blog,['image','medium','src'],null)
export const getBlogDetailImageHugeSrc = blog => _.get(blog,['image','huge','src'],null)
export const getBlogDetailPublistedAtRelative =  blog => _.get(blog,['publishedAt','relative'],"")
export const getBlogDetailPublistedAtTimestamp =  blog => _.get(blog,['publishedAt','timestamp'],Date.now())
export const getBlogDetailPublistedAtText = blog =>_.get(blog,['publishedAt','text'],"")
export const getBlogDetailTagItems = blog => _.get(blog,['tag','items'],[])
export const getBlogDetailContent = blog => _.get(blog,['content'],'')
export const getBlogDetailSlug = blog => _.get(blog,['slug'],"")
