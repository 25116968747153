import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Color, Color as Colors } from "../../src/utils/Color";
import styled, { withTheme, useTheme } from "styled-components";
import { connect } from "dva";
import Image from "next/image";
import _ from "lodash";
import Router from "next/router";
import { NextSeo } from "next-seo";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomFont from "../../components/CustomFont";
import Divider from "@material-ui/core/Divider";
import Button from "@mui/material/Button";
import { maxDevice } from "../../src/utils/breakpoints";
import * as selectors from "../../src/selectors";
import * as searchActions from "../../src/actions/search";
import Hidden from "@material-ui/core/Hidden";
import Box from "@mui/material/Box";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import RoomIcon from "@mui/icons-material/Room";
import CallIcon from "@material-ui/icons/Call";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const ImageContainer = styled.div`
  width: 240px;
  height: 100px;
  position: relative;
  margin: 20px 0;
  @media ${maxDevice.md} {
    width: 200px;
  }
`;
const ImageContainerContent = styled.div`
  position: relative;
  width: fit-content;

  @media ${maxDevice.md} {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    width: auto;
  }
`;
const FooterIconContent = styled.div`
  margin: 10px 0;
  display: flex;
  font-size: 0.9rem;
  &:hover {
    color: ${Colors.primaryColor};
    transition: 200ms ease-in;
  }
`;
const FooterSocicalMediaContent = styled.div`
  display: flex;
  justify-content: flex-end;
  @media ${maxDevice.md} {
    justify-content: flex-start;
  }
`;
const GridStyled = styled(Grid)`
  @media ${maxDevice.md} {
    &.MuiGrid-container {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }
`;
const FooterBottomSection = styled.div``;
const FooterLinkStyled = styled.a`
  color: ${Colors.blackColor};
  font-size: 0.9rem;
  &:hover {
    color: ${Colors.primaryColor};
    transition: 200ms ease-in;
  }
  @media ${maxDevice.md} {
    font-size: 0.9rem;
  }
`;
const FooterLinkStyledIcon = styled.a`
  background-color: ${Colors.blackColor};
  width: 36px;
  height: 36px;

  &:hover {
    background-color: ${Colors.primaryColor};
    transition: 200ms ease-in;
  }
  @media ${maxDevice.md} {
    width: 36px;
    height: 36px;
  }
`;

const NaviRightContent = styled.div`
  padding-left: 30px;
  //margin-top: -50px;
  @media ${maxDevice.md} {
padding-left: 0px;
    margin-top: 0px;
    }
  }
`;
const InstaIconStyled = styled.div`
  width: 100%;
  position: relative;
  aspect-ratio: 3/2;
  position: relative;
  // @media ${maxDevice.md} {
  //
  //   }
  }
`;
// const GridStyledSpacing = styled(Grid)`
//   @media ${maxDevice.md} {
//     &.MuiGrid-spacing-xs-8 .MuiGrid-item {
//       padding: 10px 16px!important;
//     }
//   }
//   }
// `;

class FooterAccordionMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ marginBottom: "30px" }}>
        <div>
          {/*<CustomFont*/}
          {/*  size="tlarge"*/}
          {/*  styles={{ fontWeight: "600", margin: "20px 0" }}*/}
          {/*>*/}
          {/*  Embark on Your Property Search with PropertyGenie*/}
          {/*</CustomFont>*/}
          {/*===============Desktop Version===================*/}
          {/*<Hidden smDown>*/}
          {/*  <CustomFont*/}
          {/*    size="small"*/}
          {/*    styles={{*/}
          {/*      fontWeight: "400",*/}
          {/*      lineHeight: "1.5",*/}
          {/*      textAlign: "justify"*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {`At PropertyGenie, we're revolutionizing Malaysia's real estate market through our fast-growing proptech platform. Explore an extensive selection of`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}property-for-sale`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`properties for Sale`}</a>{" "}*/}
          {/*    {`and`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}property-for-rent`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`properties for Rent`}</a>*/}
          {/*    {`, each meticulously detailed with interactive maps and high-quality photos. Whether you're interested in residential homes or commercial spaces, our`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}property-for-rent`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`platform`}</a>{" "}*/}
          {/*    {`is tailored to meet your specific needs.`}*/}
          {/*  </CustomFont>*/}
          {/*  <br />*/}
          {/*  <CustomFont*/}
          {/*    size="small"*/}
          {/*    styles={{*/}
          {/*      fontWeight: "400",*/}
          {/*      textAlign: "justify",*/}
          {/*      lineHeight: "1.5"*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {`Choosing your next property is a significant milestone, and we're committed to providing you with all the essential`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}loan-calculator`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`tools`}</a>{" "}*/}
          {/*    {`and `}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}insider-guide`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`insights.`}</a>{" "}*/}
          {/*    {`Browse through our comprehensive`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}looking-for-agent`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`Agent Directory`}</a>{" "}*/}
          {/*    {`to connect with qualified real estate professionals. Our `}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}looking-for-developer`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`Developer Directory`}</a>{" "}*/}
          {/*    {`offers an in-depth look at Malaysia's leading property developers, giving you a clearer understanding of the market landscape. Our`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}project`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`List of Projects`}</a>{" "}*/}
          {/*    {`showcases a diverse range of real estate opportunities, from residential to commercial. To further guide you in your property journey, our`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}insider-guide`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`Insider Guides`}</a>{" "}*/}
          {/*    {`deliver expert advice, up-to-date market trends, and invaluable property insights.`}*/}
          {/*  </CustomFont>*/}
          {/*</Hidden>*/}
          {/*======Mobile Version=======*/}
          {/*<Hidden mdUp>*/}
          {/*  <CustomFont*/}
          {/*    size="small"*/}
          {/*    styles={{*/}
          {/*      fontWeight: "400",*/}
          {/*      lineHeight: "1.5",*/}
          {/*      textAlign: "justify"*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {`At PropertyGenie, we're revolutionizing Malaysia's real estate market through our fast-growing proptech platform. Explore an extensive selection of`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}property-for-sale`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`properties for Sale`}</a>{" "}*/}
          {/*    {`and`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}property-for-rent`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`properties for Rent`}</a>*/}
          {/*    {`, each meticulously detailed with interactive maps and high-quality photos. Whether you're interested in residential homes or commercial spaces, our`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}property-for-rent`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`platform`}</a>{" "}*/}
          {/*    {`is tailored to meet your specific needs.`}*/}
          {/*  </CustomFont>*/}
          {/*  <br />*/}
          {/*  <CustomFont*/}
          {/*    size="small"*/}
          {/*    styles={{*/}
          {/*      fontWeight: "400",*/}
          {/*      textAlign: "justify",*/}
          {/*      lineHeight: "1.5"*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {`Choosing your next property is a significant milestone, and we're committed to providing you with all the essential`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}loan-calculator`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`tools`}</a>{" "}*/}
          {/*    {`and `}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}insider-guide`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`insights.`}</a>{" "}*/}
          {/*    {`Browse through our comprehensive`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}looking-for-agent`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`Agent Directory`}</a>{" "}*/}
          {/*    {`to connect with qualified real estate professionals. Our `}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}looking-for-developer`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`Developer Directory`}</a>{" "}*/}
          {/*    {`offers an in-depth look at Malaysia's leading property developers, giving you a clearer understanding of the market landscape. Our`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}project`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`List of Projects`}</a>{" "}*/}
          {/*    {`showcases a diverse range of real estate opportunities, from residential to commercial. To further guide you in your property journey, our`}{" "}*/}
          {/*    <a*/}
          {/*      href={`${process.env.WEBSITE_URL}insider-guide`}*/}
          {/*      style={{*/}
          {/*        // textDecorationLine: "underline",*/}
          {/*        color: Colors.primaryColor,*/}
          {/*        cursor: "pointer",*/}
          {/*        fontWeight: "600"*/}
          {/*      }}*/}
          {/*    >{`Insider Guides`}</a>{" "}*/}
          {/*    {`deliver expert advice, up-to-date market trends, and invaluable property insights.`}*/}
          {/*  </CustomFont>*/}
          {/*</Hidden>*/}
        </div>
        {/*<Hidden mdDown>*/}
        {/*  <Divider*/}
        {/*    style={{*/}
        {/*      backgroundColor: "#bdbdbd",*/}
        {/*      height: "2px",*/}
        {/*      marginTop: "2rem",*/}
        {/*      marginBottom: "1rem"*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Hidden>*/}
        {/*===============Bottom Section===================*/}
        <FooterBottomSection style={{ marginTop: "15px" }}>
          {/*<ImageContainerContent>*/}
          {/*  <ImageContainer>*/}
          {/*    <a href={`${process.env.WEBSITE_URL}`}>*/}
          {/*      <Image*/}
          {/*        src="/images/Logo/pg-logo-main.png"*/}
          {/*        layout="fill"*/}
          {/*        objectFit="contain"*/}
          {/*        loading="lazy"*/}
          {/*        alt="Property Genie"*/}
          {/*      />*/}
          {/*    </a>*/}
          {/*  </ImageContainer>*/}
          {/*</ImageContainerContent>*/}
          <div>
            <Grid container spacing={4}>
              <Grid item md={7} sm={12}>
                {/*<Hidden smDown>*/}
                {/*  <CustomFont*/}
                {/*    size="tlarge"*/}
                {/*    styles={{ fontWeight: "700", margin: "15px 0" }}*/}
                {/*  >*/}
                {/*    Welcome to PropertyGenie!*/}
                {/*  </CustomFont>*/}
                {/*</Hidden>*/}
                {/*<Hidden mdUp>*/}
                {/*  <div style={{ textAlign: 'center' }}>*/}
                {/*  <CustomFont*/}
                {/*    size="tlarge"*/}
                {/*    styles={{ fontWeight: "700", margin: "15px 0" }}*/}
                {/*  >*/}
                {/*    Welcome to PropertyGenie!*/}
                {/*  </CustomFont>*/}
                {/*  </div>*/}
                {/*</Hidden>*/}
                {/*<Hidden smDown>*/}
                {/*  <CustomFont*/}
                {/*    size="small"*/}
                {/*    styles={{ textAlign: "justify", lineHeight: "1.5" }}*/}
                {/*  >*/}
                {/*    {`Malaysia’s fastest growing and expanding property portal for users and real estate agents alike. */}
                {/*With PropertyGenie, discovering your ideal property - be it for`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}property-for-sale`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`buying`}</a>*/}
                {/*    {`,`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}property-for-rent`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`renting`}</a>{" "}*/}
                {/*    {`or investing in residential or commercial spaces across Malaysia.`}*/}
                {/*  </CustomFont>*/}
                {/*</Hidden>*/}
                {/*<Hidden mdUp>*/}
                {/*  <CustomFont*/}
                {/*    size="small"*/}
                {/*    styles={{ textAlign: "justify", lineHeight: "1.5" }}*/}
                {/*  >*/}
                {/*    {`Malaysia’s fastest growing and expanding property portal for users and real estate agents alike. */}
                {/*With Property Genie, discovering your ideal property - be it for`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}property-for-sale`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`buying`}</a>*/}
                {/*    {`,`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}property-for-rent`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`renting`}</a>{" "}*/}
                {/*    {`or investing in residential or commercial spaces across Malaysia.`}*/}
                {/*  </CustomFont>*/}
                {/*</Hidden>*/}
                {/*<CustomFont*/}
                {/*  size="large"*/}
                {/*  styles={{ fontWeight: "600", margin: "15px 0" }}*/}
                {/*>*/}
                {/*  Contact Us*/}
                {/*</CustomFont>*/}
                {/*<Hidden smDown>*/}
                {/*<div>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <LocalPhoneOutlinedIcon*/}
                {/*      sx={{ marginRight: 2, fontSize: 20 }}*/}
                {/*    />*/}
                {/*    <a*/}
                {/*      href={`tel:+60356220022`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`+603 5622 0022`}</a>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <WhatsAppIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <a*/}
                {/*      href={`https://api.whatsapp.com/send/?phone=60196932255&text&type=phone_number&app_absent=0`}*/}
                {/*      target={"_blank"}*/}
                {/*      rel="noopener noreferrer"*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`+6019 693 2255`}</a>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <EmailOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <a*/}
                {/*      href={`mailto:info@propertygenie.com.my`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`info@propertygenie.com.my`}</a>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <EmailOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <a*/}
                {/*      href={`mailto:advertising@propertygenie.com.my`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`advertising@propertygenie.com.my`}</a>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <EmailOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <a*/}
                {/*      href={`mailto:media@propertygenie.com.my`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`media@propertygenie.com.my`}</a>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent>*/}
                {/*    <RoomOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <a*/}
                {/*      href={`https://maps.app.goo.gl/tV8qtndtmFvc9AfN8`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`D-6-1, Menara Suezcap 1,  No.2, Jalan Kerinchi, */}
                {/*    Gerbang Kerinchi Lestari, 59200 Kuala Lumpur W.P`}</a>*/}
                {/*  </FooterIconContent>*/}
                {/*</div>*/}
                {/*</Hidden>*/}
                {/*<Hidden mdUp>*/}
                {/*<div>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <LocalPhoneOutlinedIcon*/}
                {/*      sx={{ marginRight: 2, fontSize: 20 }}*/}
                {/*    />*/}
                {/*    <CustomFont size="small">*/}
                {/*    <a*/}
                {/*      href={`tel:+60356220022`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`+603 5622 0022`}</a>*/}
                {/*    </CustomFont>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <WhatsAppIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <CustomFont size="small">*/}
                {/*    <a*/}
                {/*      href={`https://wa.me/0196932255`}*/}
                {/*      target={"_blank"}*/}
                {/*      rel="noopener noreferrer"*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`+6019 693 2255`}</a>*/}
                {/*    </CustomFont>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <EmailOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <CustomFont size="small">*/}
                {/*    <a*/}
                {/*      href={`mailto:info@propertygenie.com.my`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`info@propertygenie.com.my`}</a>*/}
                {/*    </CustomFont>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <EmailOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <CustomFont size="small">*/}
                {/*    <a*/}
                {/*      href={`mailto:advertising@propertygenie.com.my`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`advertising@propertygenie.com.my`}</a>*/}
                {/*    </CustomFont>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent style={{ alignItems: "center" }}>*/}
                {/*    <EmailOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <CustomFont size="small">*/}
                {/*    <a*/}
                {/*      href={`mailto:media@propertygenie.com.my`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`media@propertygenie.com.my`}</a>*/}
                {/*    </CustomFont>*/}
                {/*  </FooterIconContent>*/}
                {/*  <FooterIconContent>*/}
                {/*    <RoomOutlinedIcon sx={{ marginRight: 2, fontSize: 20 }} />*/}
                {/*    <CustomFont size="small">*/}
                {/*    <a*/}
                {/*      href={`https://maps.app.goo.gl/tV8qtndtmFvc9AfN8`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        cursor: "pointer"*/}
                {/*      }}*/}
                {/*    >{`D-6-1, Menara Suezcap 1,  No.2, Jalan Kerinchi, */}
                {/*    Gerbang Kerinchi Lestari, 59200 Kuala Lumpur W.P`}</a>*/}
                {/*    </CustomFont>*/}
                {/*  </FooterIconContent>*/}
                {/*</div>*/}
                {/*</Hidden>*/}

                {/*====== New Section =====*/}
                {/*  <CustomFont*/}
                {/*    size="small"*/}
                {/*    styles={{*/}
                {/*      fontWeight: "400",*/}
                {/*      lineHeight: "1.5",*/}
                {/*      textAlign: "justify",*/}
                {/*      margin: "10px 0"*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    {`At PropertyGenie, we're revolutionizing Malaysia's real estate market through our fast-growing proptech platform. Explore an extensive selection of`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}property-for-sale`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`properties for Sale`}</a>{" "}*/}
                {/*    {`and`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}property-for-rent`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`properties for Rent`}</a>*/}
                {/*    {`, each meticulously detailed with interactive maps and high-quality photos. Whether you're interested in residential homes or commercial spaces, our`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}property-for-rent`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`platform`}</a>{" "}*/}
                {/*    {`is tailored to meet your specific needs.`}*/}
                {/*  </CustomFont>*/}
                {/*  <br />*/}
                {/*  <CustomFont*/}
                {/*    size="small"*/}
                {/*    styles={{*/}
                {/*      fontWeight: "400",*/}
                {/*      textAlign: "justify",*/}
                {/*      lineHeight: "1.5"*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    {`Choosing your next property is a significant milestone, and we're committed to providing you with all the essential`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}loan-calculator`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`tools`}</a>{" "}*/}
                {/*    {`and `}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}insider-guide`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`insights.`}</a>{" "}*/}
                {/*    {`Browse through our comprehensive`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}looking-for-agent`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`Agent Directory`}</a>{" "}*/}
                {/*    {`to connect with qualified real estate professionals. Our `}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}looking-for-developer`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`Developer Directory`}</a>{" "}*/}
                {/*    {`offers an in-depth look at Malaysia's leading property developers, giving you a clearer understanding of the market landscape. Our`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}project`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`List of Projects`}</a>{" "}*/}
                {/*    {`showcases a diverse range of real estate opportunities, from residential to commercial. To further guide you in your property journey, our`}{" "}*/}
                {/*    <a*/}
                {/*      href={`${process.env.WEBSITE_URL}insider-guide`}*/}
                {/*      style={{*/}
                {/*        // textDecorationLine: "underline",*/}
                {/*        color: Colors.primaryColor,*/}
                {/*        cursor: "pointer",*/}
                {/*        fontWeight: "600"*/}
                {/*      }}*/}
                {/*    >{`Insider Guides`}</a>{" "}*/}
                {/*    {`deliver expert advice, up-to-date market trends, and invaluable property insights.`}*/}
                {/*  </CustomFont>*/}
                <CustomFont
                  size="small"
                  styles={{
                    fontWeight: "400",
                    lineHeight: "1.5",
                    textAlign: "justify",
                    margin: "10px 0"
                  }}
                >
                  {`Whether it's`}{" "}
                  <a
                    href={`${process.env.WEBSITE_URL}property-for-sale`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      fontWeight: "600"
                    }}
                  >{`property for sale`}</a>{" "}
                  {`or`}{" "}
                  <a
                    href={`${process.env.WEBSITE_URL}property-for-rent`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      fontWeight: "600"
                    }}
                  >{`houses for rent`}</a>
                  {`, explore an extensive selection of listings with detailed, 
                  interactive maps and high-quality images at Property Genie. From residential homes to commercial spaces, our `}
                  <a
                    href={`${process.env.WEBSITE_URL}`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      fontWeight: "600"
                    }}
                  >{`platform`}</a>{" "}
                  {`is tailored to meet your needs!`}
                </CustomFont>
                <CustomFont
                  size="small"
                  styles={{
                    fontWeight: "400",
                    lineHeight: "1.5",
                    textAlign: "justify",
                    margin: "10px 0"
                  }}
                >
                  {`Owning your next property is a significant milestone, and we're committed to providing you with all the necessary `}
                  <a
                    href={`${process.env.WEBSITE_URL}loan-calculator`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      fontWeight: "600"
                    }}
                  >{`tools`}</a>{" "}
                  {`and `}{" "}
                  <a
                    href={`${process.env.WEBSITE_URL}insider-guide`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      fontWeight: "600"
                    }}
                  >{`insights.`}</a>{" "}
                  {`Browse through our comprehensive `}
                  <a
                    href={`${process.env.WEBSITE_URL}looking-for-agent`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      fontWeight: "600"
                    }}
                  >{`agent directory`}</a>{" "}
                  {`to connect with a property agent in Malaysia, and our `}
                  <a
                    href={`${process.env.WEBSITE_URL}looking-for-developer`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      fontWeight: "600"
                    }}
                  >{`developer directory`}</a>{" "}
                  {`will also provide an in-depth look at Malaysia's leading property developers - giving you a clearer understanding of the market landscape.`}
                </CustomFont>
                <CustomFont
                  size="small"
                  styles={{
                    fontWeight: "400",
                    lineHeight: "1.5",
                    textAlign: "justify",
                    margin: "10px 0"
                  }}
                >
                  {`We offer a diverse range of real estate opportunities, from residential to commercial in our `}
                  <a
                    href={`${process.env.WEBSITE_URL}project`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      fontWeight: "600"
                    }}
                  >{`list of projects`}</a>
                  {". "}
                  {`Added on with our `}
                  <a
                    href={`${process.env.WEBSITE_URL}insider-guide`}
                    style={{
                      // textDecorationLine: "underline",
                      color: Colors.primaryColor,
                      cursor: "pointer",
                      fontWeight: "600"
                    }}
                  >{`insider guides,`}</a>{" "}
                  {`we’re here to further guide you in your property journey and to deliver expert advice, 
                  market trends, and property insights. Start your property search with Property Genie today!`}
                </CustomFont>
                {/*====== End of New Section ========*/}
              </Grid>
              <Grid item md={5} sm={12}>
                <NaviRightContent>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                      <Box
                        sx={{
                          display: { xs: "none", md: "block" }
                        }}
                      >
                        <CustomFont
                          size="large"
                          styles={{ fontWeight: "600", margin: "10px 0" }}
                        >
                          Company
                        </CustomFont>
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "block", md: "none" }
                        }}
                      >
                        <CustomFont
                          size="normal"
                          styles={{ fontWeight: "600", margin: "10px 0" }}
                        >
                          Company
                        </CustomFont>
                      </Box>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}about-us`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`About Us`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}contact-us`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Contact Us`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}career`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Careers`}</FooterLinkStyled>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Box
                        sx={{
                          display: { xs: "none", md: "block" }
                        }}
                      >
                        <CustomFont
                          size="large"
                          styles={{ fontWeight: "600", margin: "10px 0" }}
                        >
                          Help Center
                        </CustomFont>
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "block", md: "none" }
                        }}
                      >
                        <CustomFont
                          size="normal"
                          styles={{ fontWeight: "600", margin: "10px 0" }}
                        >
                          Help Center
                        </CustomFont>
                      </Box>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}term-of-use`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Terms Of Use`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}privacy-policy`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Privacy Policy`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}refund-policy`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Refund Policy`}</FooterLinkStyled>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Box
                        sx={{
                          display: { xs: "none", md: "block" }
                        }}
                      >
                        <CustomFont
                          size="large"
                          styles={{ fontWeight: "600", margin: "10px 0" }}
                        >
                          Discover
                        </CustomFont>
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "block", md: "none" }
                        }}
                      >
                        <CustomFont
                          size="normal"
                          styles={{ fontWeight: "600", margin: "10px 0" }}
                        >
                          Discover
                        </CustomFont>
                      </Box>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}property-for-sale`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Buy`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}property-for-rent`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Rent`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}project-listing`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Projects`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}insider-guide`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Insider Guide`}</FooterLinkStyled>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={6}>
                      <Box
                        sx={{
                          display: { xs: "none", md: "block" }
                        }}
                      >
                        <CustomFont
                          size="large"
                          styles={{ fontWeight: "600", margin: "10px 0" }}
                        >
                          Others
                        </CustomFont>
                      </Box>
                      <Box
                        sx={{
                          display: { xs: "block", md: "none" }
                        }}
                      >
                        <CustomFont
                          size="normal"
                          styles={{ fontWeight: "600", margin: "10px 0" }}
                        >
                          Others
                        </CustomFont>
                      </Box>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}looking-for-agent`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Agent Directory`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${
                            process.env.WEBSITE_URL
                          }looking-for-developer`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Developer Directory`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}transaction-and-statistics`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Transaction and Statistics`}</FooterLinkStyled>
                        <FooterLinkStyled
                          href={`${process.env.WEBSITE_URL}loan-calculator`}
                          style={{
                            // textDecorationLine: "underline",
                            cursor: "pointer",
                            margin: "3px 0"
                          }}
                        >{`Calculator`}</FooterLinkStyled>
                      </div>
                    </Grid>
                  </Grid>
                </NaviRightContent>
              </Grid>
            </Grid>
          </div>
        </FooterBottomSection>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: selectors.getAuthToken(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearFilterData: () => dispatch(searchActions.clearFilterData()),
    setCoordinateData: coordinate =>
      dispatch(searchActions.setCoordinateData(coordinate))
  };
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FooterAccordionMobile)
);
