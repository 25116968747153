import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Color, Color as Colors } from "../../src/utils/Color";
import styled, { withTheme, useTheme } from "styled-components";
import { connect } from "dva";
import _ from "lodash";
import Router from "next/router";
import { NextSeo } from "next-seo";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomFont from "../../components/CustomFont";
import Divider from "@material-ui/core/Divider";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { maxDevice } from "../../src/utils/breakpoints";
import Box from "@mui/material/Box";
import Link from 'next/link';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import {
  JohorCoordinateData,
  KedahCoordinateData,
  KelantanCoordinateData,
  MelakaCoordinateData,
  PerakCoordinateData,
  PerlisCoordinateData,
  SabahCoordinateData,
  SelangorCoordinateData,
  KualaLumpurCoordinateData,
  NegeriSembilanCoordinateData,
  LabuanCoordinateData,
  PahangCoordinateData,
  PenangCoordinateData,
  PutrajayaCoordinateData,
  SarawakCoordinateData,
  TerengganuCoordinateData,
  PetalingJayaCoordinateData,
  JohorBahruCoordinateData,
  GeorgetownCoordinateData,
  TamanPelangiCoordinateData,
  SubangJayaCoordinateData,
  MontKiaraCoordinateData,
  IskandarPuteriCoordinateData,
  UptownCoordinateData,
  BukitJalilCoordinateData,
  BukitBintangCoordinateData,
  PuchongCoordinateData,
  AmpangCoordinateData,
  BangsarCoordinateData
} from "../../src/utils/CoordinateData";
import * as selectors from "../../src/selectors";
import * as searchActions from "../../src/actions/search";
import Hidden from "@material-ui/core/Hidden";
import FooterAccordionMobile from "../FooterAccordionMobile";
import FooterBottomSection from "../FooterBottomSection";
import FooterDivider from "../../components/Footer/FooterDivider";

const FooterStateContainer = styled.div`
  font-size: 0.9rem;
  margin: 7px 0;

  &:hover {
    cursor: pointer;
    // color: ${Color.primaryColor};
    // text-decoration: underline;
  }
`;

const FooterStateContentMain = styled.div`
  display: flex;
  align-items: center;
  & p {
    margin: 5px 0;
  }
`;
const FooterStateContentSub = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  & a {
    font-size: 12px;
    padding-top: 5px;
  }
  & a:hover {
    cursor: pointer;
    color: ${Color.primaryColor};
    text-decoration: underline;
  }
`;

class FooterLocationDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMore: false,
      viewMore2: false,
      viewMore3: false,
      viewMore4: false,
      showKLSalesProperties: false,
      showKLRentProperties: false,
      showPJSalesProperties: false,
      showPJRentProperties: false,
      showJBSalesProperties: false,
      showJBRentProperties: false,
      showSerembanSalesProperties: false,
      showSerembanRentProperties: false,
      showTaipingSalesProperties: false,
      showTaipingRentProperties: false,
      showPahangSalesProperties: false,
      showPahangRentProperties: false,
      showKudatSalesProperties: false,
      showKudatRentProperties: false,
      showKKSalesProperties: false,
      showKKRentProperties: false,
      showTawauSalesProperties: false,
      showTawauRentProperties: false,
      showSandakanSalesProperties: false,
      showSandakanRentProperties: false,
      showGeorgeSalesProperties: false,
      showGeorgeRentProperties: false,
      showButterworthSalesProperties: false,
      showButterworthRentProperties: false,
      showAlorStarSalesProperties: false,
      showAlorStarRentProperties: false,
      showKBSalesProperties: false,
      showKBRentProperties: false
    };
  }

  // onClickPropertyForSale = e => {
  //    const { clearFilterData } = props;
  //    e.preventDefault();
  //    clearFilterData && clearFilterData();
  //
  //    Router.push({
  //      pathname: "/property-for-sale"
  //    });
  //  };
  //
  //
  //  onClickPropertyForRent = e => {
  //    const { clearFilterData } = props;
  //    e.preventDefault();
  //    clearFilterData && clearFilterData();
  //
  //    Router.push({
  //      pathname: "/property-for-rent"
  //    });
  //  };

  onClickPropertyRentState = (e, coordinateData) => {
    const { setCoordinateData } = this.props;

    e.preventDefault();

    setCoordinateData && setCoordinateData(coordinateData);
    Router.push({
      pathname: "/property-for-rent"
    });
  };

  onClickPropertySaleState = (e, coordinateData) => {
    const { setCoordinateData } = this.props;

    e.preventDefault();

    setCoordinateData && setCoordinateData(coordinateData);

    Router.push({
      pathname: "/property-for-sale"
    });
    console.log(coordinateData);
  };

  onClickViewMore = () => {
    this.setState({
      viewMore: !this.state.viewMore
    });
  };
  onClickViewMore2 = () => {
    this.setState({
      viewMore2: !this.state.viewMore2
    });
  };
  onClickViewMore3 = () => {
    this.setState({
      viewMore3: !this.state.viewMore3
    });
  };
  onClickViewMore4 = () => {
    this.setState({
      viewMore4: !this.state.viewMore4
    });
  };

  toggleKLSalesProperties = () => {
    this.setState({ showKLSalesProperties: !this.state.showKLSalesProperties });
  };
  toggleKLRentProperties = () => {
    this.setState({ showKLRentProperties: !this.state.showKLRentProperties });
  };
  togglePJSalesProperties = () => {
    this.setState({ showPJSalesProperties: !this.state.showPJSalesProperties });
  };
  togglePJRentProperties = () => {
    this.setState({ showPJRentProperties: !this.state.showPJRentProperties });
  };
  toggleJBSalesProperties = () => {
    this.setState({ showJBSalesProperties: !this.state.showJBSalesProperties });
  };
  toggleJBRentProperties = () => {
    this.setState({ showJBRentProperties: !this.state.showJBRentProperties });
  };
  toggleSerembanSalesProperties = () => {
    this.setState({ showSerembanSalesProperties: !this.state.showSerembanSalesProperties });
  };
  toggleSerembanRentProperties = () => {
    this.setState({ showSerembanRentProperties: !this.state.showSerembanRentProperties });
  };
  toggleTaipingSalesProperties = () => {
    this.setState({ showTaipingSalesProperties: !this.state.showTaipingSalesProperties });
  };
  toggleTaipingRentProperties = () => {
    this.setState({ showTaipingRentProperties: !this.state.showTaipingRentProperties });
  };
  togglePahangSalesProperties = () => {
    this.setState({ showPahangSalesProperties: !this.state.showPahangSalesProperties });
  };
  togglePahangRentProperties = () => {
    this.setState({ showPahangRentProperties: !this.state.showPahangRentProperties });
  };
  toggleKudatSalesProperties = () => {
    this.setState({ showKudatSalesProperties: !this.state.showKudatSalesProperties });
  };
  toggleKudatRentProperties = () => {
    this.setState({ showKudatRentProperties: !this.state.showKudatRentProperties });
  };
  toggleKKSalesProperties = () => {
    this.setState({ showKKSalesProperties: !this.state.showKKSalesProperties });
  };
  toggleKKRentProperties = () => {
    this.setState({ showKKRentProperties: !this.state.showKKRentProperties });
  };
  toggleTawauSalesProperties = () => {
    this.setState({ showTawauSalesProperties: !this.state.showTawauSalesProperties });
  };
  toggleTawauRentProperties = () => {
    this.setState({ showTawauRentProperties: !this.state.showTawauRentProperties });
  };
  toggleSandakanSalesProperties = () => {
    this.setState({ showSandakanSalesProperties: !this.state.showSandakanSalesProperties });
  };
  toggleSandakanRentProperties = () => {
    this.setState({ showSandakanRentProperties: !this.state.showSandakanRentProperties });
  };
  toggleGeorgeSalesProperties = () => {
    this.setState({ showGeorgeSalesProperties: !this.state.showGeorgeSalesProperties });
  };
  toggleGeorgeRentProperties = () => {
    this.setState({ showGeorgeRentProperties: !this.state.showGeorgeRentProperties });
  };
  toggleButterworthSalesProperties = () => {
    this.setState({ showButterworthSalesProperties: !this.state.showButterworthSalesProperties });
  };
  toggleButterworthRentProperties = () => {
    this.setState({ showButterworthRentProperties: !this.state.showButterworthRentProperties });
  };
  toggleAlorStarSalesProperties = () => {
    this.setState({ showAlorStarSalesProperties: !this.state.showAlorStarSalesProperties });
  };
  toggleAlorStarRentProperties = () => {
    this.setState({ showAlorStarRentProperties: !this.state.showAlorStarRentProperties });
  };
  toggleKBSalesProperties = () => {
    this.setState({ showKBSalesProperties: !this.state.showKBSalesProperties });
  };
  toggleKBRentProperties = () => {
    this.setState({ showKBRentProperties: !this.state.showKBRentProperties });
  };

  render() {
    const {viewMore, viewMore2, viewMore3, viewMore4, showKLSalesProperties, showPJSalesProperties, showJBSalesProperties,
      showKLRentProperties, showPJRentProperties, showJBRentProperties, showSerembanSalesProperties, showSerembanRentProperties,
      showTaipingSalesProperties, showTaipingRentProperties, showKudatSalesProperties, showKudatRentProperties,
      showKKSalesProperties, showKKRentProperties, showTawauSalesProperties, showTawauRentProperties, showSandakanSalesProperties, showSandakanRentProperties,
      showGeorgeSalesProperties, showGeorgeRentProperties, showButterworthSalesProperties, showButterworthRentProperties,
      showAlorStarSalesProperties, showAlorStarRentProperties, showKBSalesProperties, showKBRentProperties, showPahangSalesProperties, showPahangRentProperties

    } = this.state;
    return (
      <Box sx={{ borderTop: "1px solid rgb(221, 221, 221)" }}>
        <Container>

            <Grid container style={{ marginTop: "2rem" }} spacing={2}>
              <Grid item md={3} sm={3}>
                <CustomFont size="normal" styles={{ fontWeight: "500", marginBottom: "10px" }}>
                  Top Searched Property For Sale
                </CustomFont>
                <div>
                  <FooterStateContainer onClick={this.toggleKLSalesProperties}>
                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                      <p style={{ margin: "0" }}>Properties For Sale in Kuala Lumpur</p>

                      {showKLSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                    </div>
                    {showKLSalesProperties && (
                        <FooterStateContentSub>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/cheras`}>
                            Properties For Sale in Cheras
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/bukit-bintang`}>
                            Properties For Sale in Bukit Bintang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/kuchai-lama`}>
                            Properties For Sale in Kuchai Lama
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/kepong`}>
                            Properties For Sale in Kepong
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/seputeh`}>
                            Properties For Sale in Seputeh
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/salak-selatan`}>
                            Properties For Sale in Salak Selatang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/taman-ayer-panas`}>
                            Properties For Sale in Taman Ayer Panas
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/sungai-besi`}>
                            Properties For Sale in Sungai Besi
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/desa-parkcity`}>
                            Properties For Sale in Desa Park City
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/bangsar`}>
                            Properties For Sale in Bangsar
                          </a>
                        </FooterStateContentSub>
                    )}
                  </FooterStateContainer>
                  <FooterStateContainer onClick={this.togglePJSalesProperties}>
                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                      <p style={{ margin: "0" }}>Properties For Sale in Petaling Jaya</p>

                      {showPJSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                    </div>
                    {showPJSalesProperties && (
                        <FooterStateContentSub>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor/kota-damansara`}>
                            Properties For Sale in Kota Damansara
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale?q=SS2%2C-Petaling-Jaya%2C-Selangor%2C-Malaysia`}>
                            Properties For Sale in SS2
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor/subang-jaya`}>
                            Properties For Sale in Subang Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor/uep-subang-jaya`}>
                            Properties For Sale in UEP Subang Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor/puchong`}>
                            Properties For Sale in Puchong
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Bandar-Sunway%2C-Petaling-Jaya%2C-Selangor%2C-Malaysia`}>
                            Properties For Sale in Bandar Sunway
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Kinrara%2C-Puchong%2C-Selangor%2C-Malaysia`}>
                            Properties For Sale in Taman Kinrara
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kelana-Jaya%2C-SS7%2C-Petaling-Jaya%2C-Selangor%2C-Malaysia`}>
                            Properties For Sale in Kelana Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Mayang%2C-Petaling-Jaya%2C-Selangor%2C-Malaysia`}>
                            Properties For Sale in Taman Mayang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor/shah-alam`}>
                            Properties For Sale in Shah Alam
                          </a>
                        </FooterStateContentSub>
                    )}
                  </FooterStateContainer>
                  <FooterStateContainer onClick={this.toggleJBSalesProperties}>
                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                      <p style={{ margin: "0" }}>Properties For Sale in Johor Bahru</p>

                      {showJBSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                    </div>
                    {showJBSalesProperties && (
                        <FooterStateContentSub>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/permas-jaya`}>
                            Properties For Sale in Permas Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/larkin`}>
                            Properties For Sale in Larkin
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/ulu-tiram`}>
                            Properties For Sale in Ulu Tiram
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/taman-abad`}>
                            Properties For Sale in Taman Abad
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/stulang`}>
                            Properties For Sale in Stulang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/perling`}>
                            Properties For Sale in Perling
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/kempas`}>
                            Properties For Sale in Kempas
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/skudai`}>
                            Properties For Sale in Skudai
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Johor-Jaya%2C-Johor-Bahru%2C-Johor%2C-Malaysia`}>
                            Properties For Sale in Johor Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/pekan-nanas`}>
                            Properties For Sale in Pekan Nanas
                          </a>
                        </FooterStateContentSub>
                    )}
                  </FooterStateContainer>

                  <FooterStateContainer onClick={this.toggleSerembanSalesProperties}>
                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                      <p style={{ margin: "0" }}>Properties For Sale in Seremban</p>

                      {showSerembanSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                    </div>
                    {showSerembanSalesProperties && (
                        <FooterStateContentSub>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang/kemayan`}>
                            Properties For Sale in Kemayan
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/negeri-sembilan/senawang`}>
                            Properties For Sale in Senawang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Rasah%2C-Negeri-Sembilan%2C-Malaysia`}>
                            Properties For Sale in Rasah
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Paroi%2C-Seremban%2C-Negeri-Sembilan%2C-Malaysia`}>
                            Properties For Sale in Paroi
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/negeri-sembilan/port-dickson`}>
                            Properties For Sale in Port Dickson
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/negeri-sembilan/si-rusa`}>
                            Properties For Sale in Si Rusa
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/negeri-sembilan/rantau`}>
                            Properties For Sale in Rantau
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/negeri-sembilan/bandar-sri-sendayan`}>
                            Properties For Sale in Bandar Sri Sendayan
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Siliau%2C-Negeri-Sembilan%2C-Malaysia`}>
                            Properties For Sale in Siliau
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-sale/negeri-sembilan/labu`}>
                            Properties For Sale in Labu
                          </a>
                        </FooterStateContentSub>
                    )}
                  </FooterStateContainer>
                  {viewMore ? (
                    <div>
                      <FooterStateContainer onClick={this.toggleTaipingSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in Taiping</p>

                          {showTaipingSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showTaipingSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/perak/simpang`}>
                                Properties For Sale in Simpang
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Jalan-Simpang-Aman-1%2C-Taman-Simpang-Aman%2C-Simpang%2C-Perak%2C-Malaysia`}>
                                Properties For Sale in Simpang Aman
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Matang%2C-Perak%2C-Malaysia`}>
                                Properties For Sale in Matang
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/perak/changkat-jering`}>
                                Properties For Sale in Changkat Jering
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/perak/changkat-keruing`}>
                                Properties For Sale in Changkat Keruing
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/perak/simpang-pulai`}>
                                Properties For Sale in Simpang Pulai
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/perak/gopeng`}>
                                Properties For Sale in Gopeng
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/perak/malim-nawar`}>
                                Properties For Sale in Malim Nawar
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/perak/jeram`}>
                                Properties For Sale in Jeram
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampong-Mendok%2C-Perak%2C-Malaysia`}>
                                Properties For Sale in Kampong Mendok
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                      <FooterStateContainer onClick={this.togglePahangSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in Pahang</p>

                          {showPahangSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showPahangSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kuantan%2C-Pahang%2C-Malaysia`}>
                                Properties For Sale in Kuantan
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang/bentong`}>
                                Properties For Sale in Bentong
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang/karak`}>
                                Properties For Sale in Karak
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang/mentakab`}>
                                Properties For Sale in Mentakab
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang/bandar-bera`}>
                                Properties For Sale in Bandar Bera
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang/maran`}>
                                Properties For Sale in Maran
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang/kuala-krau`}>
                                Properties For Sale in Kuala Krau
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang/padang-tengku`}>
                                Properties For Sale in Padang Tengku
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang/sungai-lembing`}>
                                Properties For Sale in Sungai Lembing
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kuala-Tahan%2C-Pahang%2C-Malaysia`}>
                                Properties For Sale in Kuala Tahan
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                      <FooterStateContainer onClick={this.toggleKudatSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in Kudat</p>

                          {showKudatSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showKudatSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kpg-Dampirit%2C-Barambongan%2C-Kudat%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kpg Dampirit
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/sabah/lorong-seri-rasa`}>
                                Properties For Sale in Lorong Seri Rasa
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Tanjung-Aru%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Tanjung Aru
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kumbatang%2C-Kudat%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kumbatang Kudat
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kankan%2C-Kudat%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kankan
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampong-Muhong%2C-Matunggong%2C-Kota-Marudu%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kampong Muhong
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampong-Radus%2C-Kota-Marudu%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kampong Radus
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kpg-Taburan%2C-Kota-Belud%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kpg Taburan
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampong-Manggis%2C-Ranau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kampong Manggis
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Golong%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Golong
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                      <FooterStateContainer onClick={this.toggleKKSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in Kota Kinabalu</p>

                          {showKKSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showKKSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/sabah/donggongon`}>
                                Properties For Sale in Donggongon
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Petagas%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Petagas
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/sabah/putatan`}>
                                Properties For Sale in Putatan
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Suria%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Suria
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Khidmat%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Khidmat
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Kim-Leng%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Kim Leng
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Chin-Sang%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Chin Sang
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/sabah/likas`}>
                                Properties For Sale in Likas
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Kemajuan%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Kemajuan
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Nelly%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Nelly
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                      <FooterStateContainer onClick={this.toggleTawauSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in Tawau</p>

                          {showTawauSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showTawauSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Berkerly%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Berkerly
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Jambu%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Jambu
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Scenery%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Scenery
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Air-Panas%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Air Panas
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampung-Jawa-Lanut%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kampung Jawa Lanut
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Megah-Jaya%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Megah Jaya
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampung-Tinagat%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kampung Tinagat
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Bandar-Sri-Indah%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Bandar Sri Indah
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Tawau-Airport%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Tawau Airport
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Mata-Chinta%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Mata Chinta
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                      <FooterStateContainer onClick={this.toggleSandakanSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in Sandakan</p>

                          {showSandakanSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showSandakanSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Sentosa%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Sentosa
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Pusat-Bandar-Sandakan%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Pusat Bandar Sandakan
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Bandar-Indah%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Bandar Indah
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Mawar%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Mawar
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Kam-Jai-Yen%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Taman Kam Jai Yen
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Pulau-Berhala%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Pulau Berhala
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Bokara%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Bokara
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampung-Lupak-Meluas%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kampung Lupak Meluas
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Pulau-Nunuyan-Darat%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Pulau Nunuyan Darat
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampung-Atas-Air%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                Properties For Sale in Kampung Atas Air
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                      <FooterStateContainer onClick={this.toggleGeorgeSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in George Town</p>

                          {showGeorgeSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showGeorgeSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/air-itam`}>
                                Properties For Sale in Air Itam
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Air-Hitam%2C-Penang%2C-Malaysia`}>
                                Properties For Sale in Air Hitam
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/farlim`}>
                                Properties For Sale in Farlim
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/jelutong`}>
                                Properties For Sale in Jelutong
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Desa-Jelutong%2C-Jelutong%2C-Penang%2C-Malaysia`}>
                                Properties For Sale in Desa Jelutong
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/gelugor`}>
                                Properties For Sale in Gelugor
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/tasek-gelugor`}>
                                Properties For Sale in Tasek Gelugor
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Sungai-Dua%2C-Penang%2C-Malaysia`}>
                                Properties For Sale in Sungai Dua
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Bayan-Baru%2C-Bayan-Lepas%2C-Penang%2C-Malaysia`}>
                                Properties For Sale in Bayan Baru
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/bayan-lepas`}>
                                Properties For Sale in Bayan Lepas
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                      <FooterStateContainer onClick={this.toggleButterworthSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in Butterworth</p>

                          {showButterworthSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showButterworthSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/perai`}>
                                Properties For Sale in Perai
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/seberang-jaya`}>
                                Properties For Sale in Seberang Jaya
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/central-seberang-perai`}>
                                Properties For Sale in Central Seberang Perai
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/north-seberang-perai-district`}>
                                Properties For Sale in North Seberang Perai
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/south-seberang-perai-district`}>
                                Properties For Sale in South Seberang Perai
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/nibong-tebal`}>
                                Properties For Sale in Nibong Tebal
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/simpang-ampat`}>
                                Properties For Sale in Simpang Ampat
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Bukit-Minyak%2C-Simpang-Ampat%2C-Penang%2C-Malaysia`}>
                                Properties For Sale in Bukit Minyak
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Murai-Jaya%2C-Simpang-Ampat%2C-Penang%2C-Malaysia`}>
                                Properties For Sale in Taman Murai Jaya
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/sungai-jawi`}>
                                Properties For Sale in Sungai Jawi
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                      <FooterStateContainer onClick={this.toggleAlorStarSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in Alor Star</p>

                          {showAlorStarSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showAlorStarSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Bandar-Alor-Setar%2C-Alor-Setar%2C-Kedah%2C-Malaysia`}>
                                Properties For Sale in Bandar Alor Setar
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Simpang-Kuala%2C-Alor-Setar%2C-Kedah%2C-Malaysia`}>
                                Properties For Sale in Simpang Kuala
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/kedah/simpang-empat`}>
                                Properties For Sale in Simpang Empat
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Pendang%2C-Kedah%2C-Malaysia`}>
                                Properties For Sale in Pendang
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/kedah/kuala-kedah`}>
                                Properties For Sale in Kuala Kedah
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/kedah/kepala-batas`}>
                                Properties For Sale in Kepala Batas
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Pekan-Jitra%2C-Jitra%2C-Kedah%2C-Malaysia`}>
                                Properties For Sale in Pekan Jitra
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampung-Kelubi%2C-Jitra%2C-Kedah%2C-Malaysia`}>
                                Properties For Sale in Kampung Kelubi
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Tunku-Sarina%2C-Jitra%2C-Kedah%2C-Malaysia`}>
                                Properties For Sale in Taman Tunku Sarina
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Taman-Pulasan%2C-Jitra%2C-Kedah%2C-Malaysia`}>
                                Properties For Sale in Taman Pulasan
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                      <FooterStateContainer onClick={this.toggleKBSalesProperties}>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                          <p style={{ margin: "0" }}>Properties For Sale in Kota Bharu</p>

                          {showKBSalesProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                        </div>
                        {showKBSalesProperties && (
                            <FooterStateContentSub>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/kelantan/machang`}>
                                Properties For Sale in Machang
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/kelantan/melor`}>
                                Properties For Sale in Melor
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kadok%2C-Kelantan%2C-Malaysia`}>
                                Properties For Sale in Kadok
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Ketereh%2C-Kelantan%2C-Malaysia`}>
                                Properties For Sale in Ketereh
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/kelantan/selising`}>
                                Properties For Sale in Selising
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/kelantan/pasir-puteh`}>
                                Properties For Sale in Pasir Puteh
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Gong-Kedak%2C-Kelantan%2C-Malaysia`}>
                                Properties For Sale in Gong Kedak
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Kampung-Balai%2C-Kelantan%2C-Malaysia`}>
                                Properties For Sale in Kampung Balai
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale/kelantan/bachok`}>
                                Properties For Sale in Bachok
                              </a>
                              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Padang-Bongor%2C-Kota-Bharu%2C-Kelantan%2C-Malaysia`}>
                                Properties For Sale in Padang Bongor
                              </a>
                            </FooterStateContentSub>
                        )}
                      </FooterStateContainer>
                    </div>
                  ) : (
                    false
                  )}
                  <div style={{ textAlign: "left", marginTop: "1rem", marginBottom: "1rem", color: Colors.primaryColor, cursor: "pointer", fontSize: "0.9rem" }} onClick={this.onClickViewMore}>
                    {viewMore ? "View Less" : "View More"}
                  </div>
                </div>
              </Grid>


              <Grid item md={3} sm={3}>
                <CustomFont size="normal" styles={{ fontWeight: "500", marginBottom: "10px" }}>
                  Top Searched Property For Rent
                </CustomFont>
                <div>
                  <FooterStateContainer onClick={this.toggleKLRentProperties}>
                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                      <p style={{ margin: "0" }}>Properties For Rent in Kuala Lumpur</p>

                      {showKLRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                    </div>
                    {showKLRentProperties && (
                        <FooterStateContentSub>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/cheras`}>
                            Properties For Rent in Cheras
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/bukit-bintang`}>
                            Properties For Rent in Bukit Bintang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/kuchai-lama`}>
                            Properties For Rent in Kuchai Lama
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/kepong`}>
                            Properties For Rent in Kepong
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/seputeh`}>
                            Properties For Rent in Seputeh
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/salak-selatan`}>
                            Properties For Rent in Salak Selatang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/taman-ayer-panas`}>
                            Properties For Rent in Taman Ayer Panas
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/sungai-besi`}>
                            Properties For Rent in Sungai Besi
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/desa-parkcity`}>
                            Properties For Rent in Desa Park City
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/bangsar`}>
                            Properties For Rent in Bangsar
                          </a>
                        </FooterStateContentSub>
                    )}
                  </FooterStateContainer>
                  <FooterStateContainer onClick={this.togglePJRentProperties}>
                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                      <p style={{ margin: "0" }}>Properties For Rent in Petaling Jaya</p>

                      {showPJRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                    </div>
                    {showPJRentProperties && (
                        <FooterStateContentSub>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor/kota-damansara`}>
                            Properties For Rent in Kota Damansara
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent?q=SS2%2C-Petaling-Jaya%2C-Selangor%2C-Malaysia`}>
                            Properties For Rent in SS2
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor/subang-jaya`}>
                            Properties For Rent in Subang Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor/uep-subang-jaya`}>
                            Properties For Rent in UEP Subang Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor/puchong`}>
                            Properties For Rent in Puchong
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Bandar-Sunway%2C-Petaling-Jaya%2C-Selangor%2C-Malaysia`}>
                            Properties For Rent in Bandar Sunway
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Kinrara%2C-Puchong%2C-Selangor%2C-Malaysia`}>
                            Properties For Rent in Taman Kinrara
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kelana-Jaya%2C-SS7%2C-Petaling-Jaya%2C-Selangor%2C-Malaysia`}>
                            Properties For Rent in Kelana Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Mayang%2C-Petaling-Jaya%2C-Selangor%2C-Malaysia`}>
                            Properties For Rent in Taman Mayang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor/shah-alam`}>
                            Properties For Rent in Shah Alam
                          </a>
                        </FooterStateContentSub>
                    )}
                  </FooterStateContainer>
                  <FooterStateContainer onClick={this.toggleJBRentProperties}>
                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                      <p style={{ margin: "0" }}>Properties For Rent in Johor Bahru</p>

                      {showJBRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                    </div>
                    {showJBRentProperties && (
                        <FooterStateContentSub>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/permas-jaya`}>
                            Properties For Rent in Permas Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/larkin`}>
                            Properties For Rent in Larkin
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/ulu-tiram`}>
                            Properties For Rent in Ulu Tiram
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/taman-abad`}>
                            Properties For Rent in Taman Abad
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/stulang`}>
                            Properties For Rent in Stulang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/perling`}>
                            Properties For Rent in Perling
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/kempas`}>
                            Properties For Rent in Kempas
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/skudai`}>
                            Properties For Rent in Skudai
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Johor-Jaya%2C-Johor-Bahru%2C-Johor%2C-Malaysia`}>
                            Properties For Rent in Johor Jaya
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/pekan-nanas`}>
                            Properties For Rent in Pekan Nanas
                          </a>
                        </FooterStateContentSub>
                    )}
                  </FooterStateContainer>

                  <FooterStateContainer onClick={this.toggleSerembanRentProperties}>
                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                      <p style={{ margin: "0" }}>Properties For Rent in Seremban</p>

                      {showSerembanRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                    </div>
                    {showSerembanRentProperties && (
                        <FooterStateContentSub>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang/kemayan`}>
                            Properties For Rent in Kemayan
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/negeri-sembilan/senawang`}>
                            Properties For Rent in Senawang
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Rasah%2C-Negeri-Sembilan%2C-Malaysia`}>
                            Properties For Rent in Rasah
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Paroi%2C-Seremban%2C-Negeri-Sembilan%2C-Malaysia`}>
                            Properties For Rent in Paroi
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/negeri-sembilan/port-dickson`}>
                            Properties For Rent in Port Dickson
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/negeri-sembilan/si-rusa`}>
                            Properties For Rent in Si Rusa
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/negeri-sembilan/rantau`}>
                            Properties For Rent in Rantau
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/negeri-sembilan/bandar-sri-sendayan`}>
                            Properties For Rent in Bandar Sri Sendayan
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Siliau%2C-Negeri-Sembilan%2C-Malaysia`}>
                            Properties For Rent in Siliau
                          </a>
                          <a href={`${process.env.WEBSITE_URL}property-for-rent/negeri-sembilan/labu`}>
                            Properties For Rent in Labu
                          </a>
                        </FooterStateContentSub>
                    )}
                  </FooterStateContainer>
                  {viewMore2 ? (
                      <div>
                        <FooterStateContainer onClick={this.toggleTaipingRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Rent in Taiping</p>

                            {showTaipingRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showTaipingRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/perak/simpang`}>
                                  Properties For Rent in Simpang
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Jalan-Simpang-Aman-1%2C-Taman-Simpang-Aman%2C-Simpang%2C-Perak%2C-Malaysia`}>
                                  Properties For Rent in Simpang Aman
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Matang%2C-Perak%2C-Malaysia`}>
                                  Properties For Rent in Matang
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/perak/changkat-jering`}>
                                  Properties For Rent in Changkat Jering
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/perak/changkat-keruing`}>
                                  Properties For Rent in Changkat Keruing
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/perak/simpang-pulai`}>
                                  Properties For Rent in Simpang Pulai
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/perak/gopeng`}>
                                  Properties For Rent in Gopeng
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/perak/malim-nawar`}>
                                  Properties For Rent in Malim Nawar
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/perak/jeram`}>
                                  Properties For Rent in Jeram
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampong-Mendok%2C-Perak%2C-Malaysia`}>
                                  Properties For Rent in Kampong Mendok
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                        <FooterStateContainer onClick={this.togglePahangRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Rent in Pahang</p>

                            {showPahangRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showPahangRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kuantan%2C-Pahang%2C-Malaysia`}>
                                  Properties For Rent in Kuantan
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang/bentong`}>
                                  Properties For Rent in Bentong
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang/karak`}>
                                  Properties For Rent in Karak
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang/mentakab`}>
                                  Properties For Rent in Mentakab
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang/bandar-bera`}>
                                  Properties For Rent in Bandar Bera
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang/maran`}>
                                  Properties For Rent in Maran
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang/kuala-krau`}>
                                  Properties For Rent in Kuala Krau
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang/padang-tengku`}>
                                  Properties For Rent in Padang Tengku
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang/sungai-lembing`}>
                                  Properties For Rent in Sungai Lembing
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kuala-Tahan%2C-Pahang%2C-Malaysia`}>
                                  Properties For Rent in Kuala Tahan
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                        <FooterStateContainer onClick={this.toggleKudatRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Rent in Kudat</p>

                            {showKudatRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showKudatRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kpg-Dampirit%2C-Barambongan%2C-Kudat%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kpg Dampirit
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/sabah/lorong-seri-rasa`}>
                                  Properties For Rent in Lorong Seri Rasa
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Tanjung-Aru%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Tanjung Aru
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kumbatang%2C-Kudat%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kumbatang Kudat
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kankan%2C-Kudat%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kankan
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampong-Muhong%2C-Matunggong%2C-Kota-Marudu%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kampong Muhong
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampong-Radus%2C-Kota-Marudu%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kampong Radus
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kpg-Taburan%2C-Kota-Belud%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kpg Taburan
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampong-Manggis%2C-Ranau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kampong Manggis
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Golong%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Golong
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                        <FooterStateContainer onClick={this.toggleKKRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Rent in Kota Kinabalu</p>

                            {showKKRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showKKRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/sabah/donggongon`}>
                                  Properties For Rent in Donggongon
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Petagas%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Petagas
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/sabah/putatan`}>
                                  Properties For Rent in Putatan
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Suria%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Suria
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Khidmat%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Khidmat
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Kim-Leng%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Kim Leng
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Chin-Sang%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Chin Sang
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/sabah/likas`}>
                                  Properties For Rent in Likas
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Kemajuan%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Kemajuan
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Nelly%2C-Kota-Kinabalu%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Nelly
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                        <FooterStateContainer onClick={this.toggleTawauRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Rent in Tawau</p>

                            {showTawauRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showTawauRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Berkerly%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Berkerly
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Jambu%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Jambu
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Scenery%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Scenery
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Air-Panas%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Air Panas
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampung-Jawa-Lanut%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kampung Jawa Lanut
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Megah-Jaya%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Megah Jaya
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampung-Tinagat%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kampung Tinagat
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Bandar-Sri-Indah%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Bandar Sri Indah
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Tawau-Airport%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Tawau Airport
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Mata-Chinta%2C-Tawau%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Mata Chinta
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                        <FooterStateContainer onClick={this.toggleSandakanRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Rent in Sandakan</p>

                            {showSandakanRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showSandakanRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Sentosa%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Sentosa
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Pusat-Bandar-Sandakan%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Pusat Bandar Sandakan
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Bandar-Indah%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Bandar Indah
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Mawar%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Mawar
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Kam-Jai-Yen%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Taman Kam Jai Yen
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Pulau-Berhala%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Pulau Berhala
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Bokara%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Bokara
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampung-Lupak-Meluas%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kampung Lupak Meluas
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Pulau-Nunuyan-Darat%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Pulau Nunuyan Darat
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampung-Atas-Air%2C-Sandakan%2C-Sabah%2C-Malaysia`}>
                                  Properties For Rent in Kampung Atas Air
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                        <FooterStateContainer onClick={this.toggleGeorgeRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Rent in George Town</p>

                            {showGeorgeRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showGeorgeRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/air-itam`}>
                                  Properties For Rent in Air Itam
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Air-Hitam%2C-Penang%2C-Malaysia`}>
                                  Properties For Rent in Air Hitam
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/farlim`}>
                                  Properties For Rent in Farlim
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/jelutong`}>
                                  Properties For Rent in Jelutong
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Desa-Jelutong%2C-Jelutong%2C-Penang%2C-Malaysia`}>
                                  Properties For Rent in Desa Jelutong
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/gelugor`}>
                                  Properties For Rent in Gelugor
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/tasek-gelugor`}>
                                  Properties For Rent in Tasek Gelugor
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Sungai-Dua%2C-Penang%2C-Malaysia`}>
                                  Properties For Rent in Sungai Dua
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Bayan-Baru%2C-Bayan-Lepas%2C-Penang%2C-Malaysia`}>
                                  Properties For Rent in Bayan Baru
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/bayan-lepas`}>
                                  Properties For Rent in Bayan Lepas
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                        <FooterStateContainer onClick={this.toggleButterworthRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Rent in Butterworth</p>

                            {showButterworthRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showButterworthRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/perai`}>
                                  Properties For Rent in Perai
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/seberang-jaya`}>
                                  Properties For Rent in Seberang Jaya
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/central-seberang-perai`}>
                                  Properties For Rent in Central Seberang Perai
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/north-seberang-perai-district`}>
                                  Properties For Rent in North Seberang Perai
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/south-seberang-perai-district`}>
                                  Properties For Rent in South Seberang Perai
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/nibong-tebal`}>
                                  Properties For Sale in Nibong Tebal
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/simpang-ampat`}>
                                  Properties For Rent in Simpang Ampat
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Bukit-Minyak%2C-Simpang-Ampat%2C-Penang%2C-Malaysia`}>
                                  Properties For Rent in Bukit Minyak
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Murai-Jaya%2C-Simpang-Ampat%2C-Penang%2C-Malaysia`}>
                                  Properties For Rent in Taman Murai Jaya
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/sungai-jawi`}>
                                  Properties For Rent in Sungai Jawi
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                        <FooterStateContainer onClick={this.toggleAlorStarRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Rent in Alor Star</p>

                            {showAlorStarRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showAlorStarRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Bandar-Alor-Setar%2C-Alor-Setar%2C-Kedah%2C-Malaysia`}>
                                  Properties For Rent in Bandar Alor Setar
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Simpang-Kuala%2C-Alor-Setar%2C-Kedah%2C-Malaysia`}>
                                  Properties For Rent in Simpang Kuala
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/kedah/simpang-empat`}>
                                  Properties For Rent in Simpang Empat
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Pendang%2C-Kedah%2C-Malaysia`}>
                                  Properties For Rent in Pendang
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/kedah/kuala-kedah`}>
                                  Properties For Rent in Kuala Kedah
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/kedah/kepala-batas`}>
                                  Properties For Rent in Kepala Batas
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Pekan-Jitra%2C-Jitra%2C-Kedah%2C-Malaysia`}>
                                  Properties For Rent in Pekan Jitra
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampung-Kelubi%2C-Jitra%2C-Kedah%2C-Malaysia`}>
                                  Properties For Rent in Kampung Kelubi
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Tunku-Sarina%2C-Jitra%2C-Kedah%2C-Malaysia`}>
                                  Properties For Rent in Taman Tunku Sarina
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Taman-Pulasan%2C-Jitra%2C-Kedah%2C-Malaysia`}>
                                  Properties For Rent in Taman Pulasan
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                        <FooterStateContainer onClick={this.toggleKBRentProperties}>
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", lineHeight: "22px" }}>
                            <p style={{ margin: "0" }}>Properties For Sale in Kota Bharu</p>

                            {showKBRentProperties ? <ExpandLessIcon style={{fontSize: "1.3rem"}} /> : <ExpandMoreIcon style={{fontSize: "1.3rem"}} />}
                          </div>
                          {showKBRentProperties && (
                              <FooterStateContentSub>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/kelantan/machang`}>
                                  Properties For Rent in Machang
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/kelantan/melor`}>
                                  Properties For Rent in Melor
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kadok%2C-Kelantan%2C-Malaysia`}>
                                  Properties For Rent in Kadok
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Ketereh%2C-Kelantan%2C-Malaysia`}>
                                  Properties For Rent in Ketereh
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/kelantan/selising`}>
                                  Properties For Rent in Selising
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/kelantan/pasir-puteh`}>
                                  Properties For Rent in Pasir Puteh
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Gong-Kedak%2C-Kelantan%2C-Malaysia`}>
                                  Properties For Rent in Gong Kedak
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Kampung-Balai%2C-Kelantan%2C-Malaysia`}>
                                  Properties For Rent in Kampung Balai
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent/kelantan/bachok`}>
                                  Properties For Rent in Bachok
                                </a>
                                <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Padang-Bongor%2C-Kota-Bharu%2C-Kelantan%2C-Malaysia`}>
                                  Properties For Rent in Padang Bongor
                                </a>
                              </FooterStateContentSub>
                          )}
                        </FooterStateContainer>
                      </div>
                  ) : (
                    false
                  )}
                  <div style={{ textAlign: "left", marginTop: "1rem", marginBottom: "1rem", color: Colors.primaryColor, cursor: "pointer", fontSize: "0.9rem" }} onClick={this.onClickViewMore2}>
                    {viewMore2 ? "View Less" : "View More"}
                  </div>
                </div>
              </Grid>
              <Grid item md={3} sm={3}>
                <CustomFont size="normal" styles={{ fontWeight: "500", marginBottom: "10px" }}>
                  Malaysia Property For Sale
                </CustomFont>
                <div>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor`}>
                      Properties For Sale in Selangor
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-sale/perak`}>
                      Properties For Sale in Perak
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-sale/sabah`}>
                      Properties For Sale in Sabah
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-sale/kelantan`}>
                      Properties For Sale in Kelantan
                    </a>
                  </FooterStateContainer>
                  {viewMore3 ? (
                    <div>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/johor`}>
                          Properties For Sale in Johor
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/melaka`}>
                          Properties For Sale in Melaka
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/perlis`}>
                          Properties For Sale in Perlis
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/penang`}>
                          Properties For Sale in Penang
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang`}>
                          Properties For Sale in Pahang
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/putrajaya`}>
                          Properties For Sale in Putrajaya
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/labuan`}>
                          Properties For Sale in Labuan
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur`}>
                          Properties For Sale in Kuala Lumpur
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/negeri-sembilan`}>
                          Properties For Sale in Negeri Sembilan
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/sarawak`}>
                          Properties For Sale in Sarawak
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-sale/terengganu`}>
                          Properties For Sale in Terengganu
                        </a>
                      </FooterStateContainer>
                    </div>
                  ) : (
                    false
                  )}
                  <div style={{ textAlign: "left", marginTop: "1rem", marginBottom: "1rem", color: Colors.primaryColor, cursor: "pointer", fontSize: "0.9rem" }} onClick={this.onClickViewMore3}>
                    {viewMore3 ? "View Less" : "View More"}
                  </div>
                </div>
              </Grid>
              <Grid item md={3} sm={3}>
                <CustomFont size="normal" styles={{ fontWeight: "500", marginBottom: "10px" }}>
                  Malaysia Property For Rent
                </CustomFont>
                <div>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor`}>
                      Properties For Rent in Selangor
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-rent/perak`}>
                      Properties For Rent in Perak
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-rent/sabah`}>
                      Properties For Rent in Sabah
                    </a>
                  </FooterStateContainer>
                  <FooterStateContainer>
                    <a href={`${process.env.WEBSITE_URL}property-for-rent/kelantan`}>
                      Properties For Rent in Kelantan
                    </a>
                  </FooterStateContainer>
                  {viewMore4 ? (
                    <div>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/johor`}>
                          Properties For Rent in Johor
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/melaka`}>
                          Properties For Rent in Melaka
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/perlis`}>
                          Properties For Rent in Perlis
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/penang`}>
                          Properties For Rent in Penang
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang`}>
                          Properties For Rent in Pahang
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/putrajaya`}>
                          Properties For Rent in Putrajaya
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/labuan`}>
                          Properties For Rent in Labuan
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur`}>
                          Properties For Rent in Kuala Lumpur
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/negeri-sembilan`}>
                          Properties For Rent in Negeri Sembilan
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/sarawak`}>
                          Properties For Rent in Sarawak
                        </a>
                      </FooterStateContainer>
                      <FooterStateContainer>
                        <a href={`${process.env.WEBSITE_URL}property-for-rent/terengganu`}>
                          Properties For Rent in Terengganu
                        </a>
                      </FooterStateContainer>
                    </div>
                  ) : (
                    false
                  )}
                  <div style={{ textAlign: "left", marginTop: "1rem", marginBottom: "1rem", color: Colors.primaryColor, cursor: "pointer", fontSize: "0.9rem" }} onClick={this.onClickViewMore4}>
                    {viewMore4 ? "View Less" : "View More"}
                  </div>
                </div>
              </Grid>
            </Grid>

        </Container>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: selectors.getAuthToken(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearFilterData: () => dispatch(searchActions.clearFilterData()),
    setCoordinateData: coordinate =>
      dispatch(searchActions.setCoordinateData(coordinate))
  };
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FooterLocationDesktop)
);
