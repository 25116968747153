/**
 * This file created by HAUYANG on 26/02/2018.
 * Copyright 2017-present, project-t-.
 * All rights reserved.
 */

import * as fromMovement from "./movement";
import * as fromGlobal from "./global";
import * as fromAuth from "./auth";
import * as fromCommon from "./common";
import * as fromProfile from "./profile";
import * as fromPassword from "./password";
import * as fromDashboard from "./dashboard";
import _ from "lodash";
import * as fromImage from "./image";
import * as fromBlog from "./blog";
import * as fromUser from "./user";
import * as fromTopUp from "./topUp";
import * as fromProduct from "./product";
import * as fromOrder from "./order";
import * as fromProperty from "./property";
import * as fromProject from "./project";
import * as fromSearch from "./search";
import * as fromNews from "./news";
import * as fromAgent from "./agent";
import * as fromVerification from "./verification";
import * as fromPost from "./post";
import * as fromWishlist from "./wishlist";
import * as fromListingTrans from "./listingTrans";
import {getAgentListSaleDetailSuccess} from "../actions/profile";

// movement
export const getMovementList = state => fromMovement.getMovementList(state.movement);
export const getMovementisSuccessUpdated = state => fromMovement.getMovementisSuccessUpdated(state.movement);
export const getMovementDetails = (state, rowIndex) =>
  fromMovement.getMovementDetails(state.movement, rowIndex);

//global

export const getGlobalLanguage = state => fromGlobal.getGlobalLanguage(state.global);
export const getGlobalIsCompleted = state => fromGlobal.getIsRehydrationCompleted(state.global);
export const getHomeLoading = state => fromGlobal.getHomeLoading(state.global);
export const getHomeData = state => fromGlobal.getHomeData(state.global);

//auth
export const getAuthToken = state => fromAuth.getAuthToken(state.auth);
export const getAuthAgentToken = state => fromAuth.getAuthAgentToken(state.auth);
export const getAuthFirebaseLoading = state => fromAuth.getAuthFirebaseLoading(state.auth);
export const getAuthFirebaseStatus = state => fromAuth.getAuthFirebaseStatus(state.auth);
export const getVerifyPhoneNumberLoading = state => fromAuth.getVerifyPhoneNumberLoading(state.auth);
export const getVerifyPhoneNumberStatus = state => fromAuth.getVerifyPhoneNumberStatus(state.auth);
export const getVerifyPhoneNumberData = state => fromAuth.getVerifyPhoneNumberData(state.auth);
export const getAuthSignInLoading = state => fromAuth.getAuthSignInLoading(state.auth);
export const getAuthSignInStatus = state => fromAuth.getAuthSignInStatus(state.auth);
export const getVerifyEmailLoading = state => fromAuth.getVerifyEmailLoading(state.auth);
export const getVerifyEmailStatus = state => fromAuth.getVerifyEmailStatus(state.auth);
export const getVerifyEmailData = state => fromAuth.getVerifyEmailData(state.auth);
export const getAuthSignUpLoading = state => fromAuth.getAuthSignUpLoading(state.auth);
export const getAuthSignUpStatus = state => fromAuth.getAuthSignUpStatus(state.auth);
export const getAuthSignUpData = state => fromAuth.getAuthSignUpData(state.auth);

//common
export const getCommonDataMobilePrefix = state => fromCommon.getCommonDataMobilePrefix(state.common);
export const getCommonDataRole = state => fromCommon.getCommonDataRole(state.common);
export const getCommonDataGender = state => fromCommon.getCommonDataGender(state.common);
export const getCommonCategoryData = state => fromCommon.getCommonCategoryData(state.common);
export const getCommonBlogStatus = state => fromCommon.getCommonBlogStatus(state.common);
export const getCommonDataBlogStatus = state => fromCommon.getCommonDataBlogStatus(state.common);
export const getCommonDataAttributes = state => fromCommon.getCommonDataAttributes(state.common);
export const getCommonTagData = state => fromCommon.getCommonTagData(state.common);
export const getCommonDataFilter = state => fromCommon.getCommonDataFilter(state.common);
export const getCommonDataEnum = state => fromCommon.getCommonDataEnum(state.common);
export const getCommonDataOptions = state => fromCommon.getCommonDataOptions(state.common);
export const getCommonDataCity = state => fromCommon.getCommonDataCity(state.common);
export const getCommonDataState = state => fromCommon.getCommonDataState(state.common);
export const getCommonDataLoading = state => fromCommon.getCommonDataLoading(state.common)

export const getCommonDataSort = state => fromCommon.getCommonDataSort(state.common);
export const getCommonAttributeTodayNewBlogCount = attributes =>
  fromCommon.getCommonAttributeTodayNewBlogCount(attributes);
export const getCommonAttributeTodayNewEventCount = attributes =>
  fromCommon.getCommonAttributeTodayNewEventCount(attributes);

export const getCommonTopUpData = state => fromCommon.getCommonTopUpData(state.common);
export const getCommonTopUpLoading = state => fromCommon.getCommonTopUpLoading(state.common);

export const getCommonDataRoleOptions = state =>
  _.flatMap(getCommonDataRole(state), item => {
    return {
      text: item.value,
      value: item.key
    };
  });

export const getCommonDataBlogStatusOptions = state =>
  _.flatMap(getCommonDataBlogStatus(state), item => {
    return {
      text: item.value,
      value: item.key
    };
  });

export const getCommonDataGenderOptions = state =>
  _.flatMap(getCommonDataGender(state), item => {
    return {
      text: item.value,
      value: item.key
    };
  });

export const getCommonWalletRequestStatus = state => fromCommon.getCommonWalletRequestStatus(state.common);
export const getCommonDataOptionsPostCategory = state => fromCommon.getCommonDataOptionsPostCategory(state.common)
export const getCommonOptionsCountry = state => fromCommon.getCommonOptionsCountry(state.common)
export const getCommonOptionsStateCode = state => fromCommon.getCommonOptionsStateCode(state.common)
export const getCommonFaqLoading = state => fromCommon.getCommonFaqLoading(state.common)
export const getCommonFaqData = state => fromCommon.getCommonFaqData(state.common)
export const getCommonOptionsUserPropertyStatus =state => fromCommon.getCommonOptionsUserPropertyStatus(state.common)

//profile
export const getProfileData = state => fromProfile.getProfileData(state.profile);
export const getProfileLoading = state => fromProfile.getProfileLoading(state.profile);
export const getProfileChangePasswordStatus = state =>
  fromProfile.getProfileChangePasswordStatus(state.profile);
export const getProfileChangePasswordLoading = state =>
  fromProfile.getProfileChangePasswordLoading(state.profile);
export const getProfileAvatarLoading = state => fromProfile.getProfileAvatarLoading(state.profile);
export const getProfileUpdateProfileLoading = state =>
  fromProfile.getProfileUpdateProfileLoading(state.profile);

export const getProfileBindPhoneNumberStatus = state => fromProfile.getProfileBindPhoneNumberStatus(state.profile)
export const getAgentProfileLoading = state => fromProfile.getAgentProfileLoading(state.profile)
export const getAgentProfileData = (state,id) => fromProfile.getAgentProfileData(state.profile,id)

export const getAccountListAgentLoading = state => fromProfile.getAccountListAgentLoading(state.profile)
export const getAccountListAgentItems = state => fromProfile.getAccountListAgentItems(state.profile)
export const getAccountListAgentPagination = state => fromProfile.getAccountListAgentPagination(state.profile)


export const getAccountListDeveloperLoading = state => fromProfile.getAccountListDeveloperLoading(state.profile)
export const getAccountListDeveloperItems = state => fromProfile.getAccountListDeveloperItems(state.profile)
export const getAccountListDeveloperPagination  = state => fromProfile.getAccountListDeveloperPagination(state.profile)

export const getProfileChangePhoneNumberLoading = state => fromProfile.getProfileChangePhoneNumberLoading(state.profile)
export const getProfileChangePhoneNumberStatus = state => fromProfile.getProfileChangePhoneNumberStatus(state.profile)
export const getProfileChangeEmailLoading = state => fromProfile.getProfileChangeEmailLoading(state.profile)
export const getProfileChangeEmailStatus = state => fromProfile.getProfileChangeEmailStatus(state.profile)

export const getAgentListSaleDetailLoading = state => fromProfile.getAgentListSaleDetailLoading(state.profile)
export const getAgentListSaleDetailItems = state => fromProfile.getAgentListSaleDetailItems(state.profile)
export const getAgentListSaleDetailPagination  = state => fromProfile.getAgentListSaleDetailPagination(state.profile)
export const getAgentListRentDetailLoading = state => fromProfile.getAgentListRentDetailLoading(state.profile)
export const getAgentListRentDetailItems = state => fromProfile.getAgentListRentDetailItems(state.profile)
export const getAgentListRentDetailPagination  = state => fromProfile.getAgentListRentDetailPagination(state.profile)
export const getDeveloperListDetailLoading = state => fromProfile.getDeveloperListDetailLoading(state.profile)
export const getDeveloperListDetailItems = state => fromProfile.getDeveloperListDetailItems(state.profile)
export const getDeveloperListDetailPagination  = state => fromProfile.getDeveloperListDetailPagination(state.profile)


//password
export const getPasswordForgetLoading = state => fromPassword.getPasswordForgetLoading(state.password);
export const getPasswordForgetStatus = state => fromPassword.getPasswordForgetStatus(state.password);
export const getPasswordForgetData = state => fromPassword.getPasswordForgetData(state.password);
export const getPasswordResetLoading = state => fromPassword.getPasswordResetLoading(state.password);
export const getPasswordResetStatus = state => fromPassword.getPasswordResetStatus(state.password);
export const getPasswordResetData = state => fromPassword.getPasswordResetData(state.password);

//dashboard
export const getDashboardLoading = state => fromDashboard.getDashboardLoading(state.dashboard);
//dashboardUser
export const getDashboardPortalUserListItem = state =>
  fromDashboard.getDashboardPortalUserListItem(state.dashboard);
export const getDashboardPortalUserListMeta = state =>
  fromDashboard.getDashboardPortalUserListMeta(state.dashboard);
//dashboardCategory
export const getDashboardPortalCategoryListItem = state =>
  fromDashboard.getDashboardPortalCategoryListItem(state.dashboard);
export const getDashboardPortalCategoryListMeta = state =>
  fromDashboard.getDashboardPortalCategoryListMeta(state.dashboard);
export const getDashboardUpdateCategoryStatus = state =>
  fromDashboard.getDashboardUpdateCategoryStatus(state.dashboard);
export const getDashboardNewCategoryStatus = state =>
  fromDashboard.getDashboardNewCategoryStatus(state.dashboard);
//dashboardBlog
export const getDashboardPortalBlogListItem = state =>
  fromDashboard.getDashboardPortalBlogListItem(state.dashboard);
export const getDashboardPortalBlogListMeta = state =>
  fromDashboard.getDashboardPortalBlogListMeta(state.dashboard);
export const getDashboardCreateBlogStatus = state =>
  fromDashboard.getDashboardCreateBlogStatus(state.dashboard);
export const getDashboardBlogDetail = (state, id) =>
  fromDashboard.getDashboardBlogDetail(state.dashboard, id);
export const getDashboardPortalCreateBlogData = state =>
  fromDashboard.getDashboardPortalCreateBlogData(state.dashboard);

//Common Meta Getter
export const getMetaTotalCount = meta => _.get(meta, ["totalCount"], 0);
export const getMetaPageCount = meta => _.get(meta, ["pageCount"], 0);
export const getMetaCurrentPage = meta => _.get(meta, ["currentPage"], 0);
export const getMetaPerPage = meta => _.get(meta, ["perPage"], 0);

//image
export const getImageLoading = state => fromImage.getImageLoading(state.image);
export const getImageData = state => fromImage.getImageData(state.image);
export const getImageDataWithType = (state, type) => fromImage.getImageDataWithType(state.image, type);

//insider-guide
export const getBlogLoading = state => fromBlog.getBlogLoading(state.blog);
export const getBlogDetailData = state => fromBlog.getBlogDetailData(state.blog);
export const getBlogData = state => fromBlog.getBlogDataItems(state.blog);
export const getBlogDataMeta = state => fromBlog.getBlogDataMeta(state.blog);
export const getBlogDataTag = state => fromBlog.getBlogDataTag(state.blog);
export const getBlogDataAuthor = state => fromBlog.getBlogDataAuthor(state.blog);
export const getBlogDataCategory = state => fromBlog.getBlogDataCategory(state.blog);

export const getUserLoading = state => fromUser.getUserLoading(state.user);
export const getUserData = state => fromUser.getUserData(state.user);
export const getUserBlogItems = state => fromUser.getUserBlogItems(state.user);
export const getUserBlogMeta = state => fromUser.getUserBlogMeta(state.user);

//top up
export const getTopUpLoading = state => fromTopUp.getTopUpLoading(state.topUp);
export const getTopUpStatus = state => fromTopUp.getTopUpStatus(state.topUp);
export const getTopUpRequestListLoading = state => fromTopUp.getTopUpRequestLoading(state.topUp);
export const getTopUpRequestListItems = state => fromTopUp.getTopUpRequestItems(state.topUp);
export const getTopUpRequestListPagination = state => fromTopUp.getTopUpRequestPagination(state.topUp);
export const getTopUpTransactionListLoading = state => fromTopUp.getTopUpTransactionLoading(state.topUp);
export const getTopUpTransactionListItems = state => fromTopUp.getTopUpTransactionItems(state.topUp);
export const getTopUpTransactionListPagination = state =>
  fromTopUp.getTopUpTransactionPagination(state.topUp);

//product
export const getProductListLoading = state => fromProduct.getProductListLoading(state.product);
export const getProductListItems = state => fromProduct.getProductListItems(state.product);
export const getProductDetailData = (state, id) => fromProduct.getProductDetailData(state.product, id);
export const getProductDetailLoading = state => fromProduct.getProductDetailLoading(state.product);

//order
export const getOrderListItems = state => fromOrder.getOrderListItems(state.order);
export const getOrderListLoading = state => fromOrder.getOrderListLoading(state.order);
export const getOrderListPagination = state => fromOrder.getOrderListPagination(state.order);
export const getCreateOrderStatus = state => fromOrder.getCreateOrderStatus(state.order);
export const getCreateOrderLoading = state => fromOrder.getCreateOrderLoading(state.order);

export const getOrderHistoryListItems = state => fromOrder.getOrderHistoryListItems(state.order)
export const getOrderHistoryListPagination = state => fromOrder.getOrderHistoryListPagination(state.order)
export const getOrderHistoryListLoading = state => fromOrder.getOrderHistoryListLoading(state.order)


//Payment order
export const getOrderPaymentDetailLoading = state => fromOrder.getOrderPaymentDetailLoading(state.order);

export const getOrderPaymentDetailStatus = state => fromOrder.getOrderPaymentDetailStatus(state.order);

export const getOrderPaymentDetailItem = state => fromOrder.getOrderPaymentDetailItem(state.order);


//property
export const getPropertyListLoading = state => fromProperty.getPropertyListLoading(state.property);
export const getPropertyListItems = (state, route) =>
  fromProperty.getPropertyListItems(state.property, route);
export const getPropertyListPagination = (state, route) =>
  fromProperty.getPropertyListPagination(state.property, route);
export const getPropertyDetailLoading = state => fromProperty.getPropertyDetailLoading(state.property);
export const getPropertyDetailItems = (state, id) => fromProperty.getPropertyDetailItems(state.property, id);
export const getPropertyToggleWishlistStatus = state => fromProperty.getPropertyToggleWishlistStatus(state.property)

export const getPropertyPostUserListingStatus = state => fromProperty.getPropertyPostUserListingStatus(state.property);
export const getPropertyPostUserListingItem = state => fromProperty.getPropertyPostUserListingItem(state.property);

export const getPropertySearchProjectItems = state => fromProperty.getPropertySearchProjectItems(state.property);
export const getPropertySearchProjectLoading = state => fromProperty.getPropertySearchProjectLoading(state.property);
export const getPropertyPostUserListingLoading = state =>
  fromProperty.getPropertyPostUserListingLoading(state.property);
export const getPropertyPostUserListingItems = (state, id) =>
  fromProperty.getPropertyPostUserListingItems(state.property, id);
export const getPropertyUserDetailItems = (state, id) =>
  fromProperty.getPropertyUserDetailItems(state.property, id);
export const getPropertySearchStateLoading = state => fromProperty.getUserPropertySearchStateLoading(state.property);
//
export const getPropertyListingImageLoading = state => fromProperty.getUserPropertyListingImageLoading(state.property);
export const getPropertyListingImage = state => fromProperty.getUserPropertyListingImage(state.property);
export const getPropertyDeleteListingImageData = state => fromProperty.getAgentDeleteListingImageData(state.property);
export const getUserPropertyListingDetailLoading = state => fromProperty.getUserPropertyListingDetailLoading(state.property);
export const getUserPropertySearchStateItems = state => fromProperty.getUserPropertySearchStateItems(state.property);
export const getUserPropertySearchCityItems = state => fromProperty.getUserPropertySearchCityItems(state.proeprty);
export const getUserPropertyListImageDefaultStatus = state => fromProperty.getUserPropertyListImageDefaultStatus(state.property);
export const getUserPropertyListImageDefaultData = state => fromProperty.getUserPropertyListImageDefaultData(state.property);
export const getUserPropertyListImageDefaultLoading = state =>
  fromProperty.getUserPropertyListImageDefaultLoading(state.property);
export const getPropertyPutUserListingLoading = state => fromProperty.getPropertyPutUserListingLoading(state.property);
export const getPropertyPutUserListingStatus = state => fromProperty.getPropertyPutUserListingStatus(state.property);
export const getUserPropertyListingImage = state => fromProperty.getUserPropertyListingImage(state.property);
export const getUserPropertyDeleteListingImageData = state => fromProperty.getUserPropertyDeleteListingImageData(state.property);
export const getUserPropertyDeleteListingImageLoading = state =>
  fromProperty.getUserPropertyDeleteListingImageLoading(state.property);
// export const getPropertyUserPriceDetail = (state, id) =>
//   fromProperty.getPropertyUserPriceDetail(state.property, id);
export const getPropertyPutUserPriceListingLoading = state => fromProperty.getPropertyPutUserPriceListingLoading(state.property);
export const getPropertyPutUserPriceListingStatus = state => fromProperty.getPropertyPutUserPriceListingStatus(state.property);

export const getPropertyUserPropertyItems = (state) => fromProperty.getPropertyUserPropertyItems(state.property)
export const getPropertyUserPropertyPagination = state => fromProperty.getPropertyUserPropertyPagination(state.property)
export const getPropertyUserPropertyLoading = state => fromProperty.getPropertyUserPropertyLoading(state.property)
export const getPropertyPutStatusLoading = state => fromProperty.getPropertyPutStatusLoading(state.property)
export const getPropertyPutStatusStatus = state => fromProperty.getPropertyPutStatusStatus(state.property)
export const getPropertyMyPropertyListingItems = state => fromProperty.getPropertyMyPropertyListingItems(state.property)
export const getPropertyMyPropertyListingPagination = state => fromProperty.getPropertyMyPropertyListingPagination(state.property)
export const getPropertyMyPropertyListingLoading = state => fromProperty.getPropertyMyPropertyListingLoading(state.property)
export const getPropertyStatisticDataItems = state => fromProperty.getPropertyStatisticDataItems(state.property)
export const getPropertyStatisticDataTotal = state => fromProperty.getPropertyStatisticDataTotal(state.property)
export const getPropertyStatisticLoading = state => fromProperty.getPropertyStatisticLoading(state.property)
export const getPropertyDataFilterStatusData = state => fromProperty.getPropertyDataFilterStatusData(state.property)

export const getPropertyPutPropertyDetailStatusLoading  = state=> fromProperty.getPropertyPutPropertyDetailStatusLoading(state.property)
export const getPropertyPutPropertyDetailStatusStatus  = state=> fromProperty.getPropertyPutPropertyDetailStatusStatus(state.property)
//project
export const getProjectListLoading = state => fromProject.getProjectListLoading(state.project);
export const getProjectListItems = (state, route) => fromProject.getProjectListItems(state.project, route);
export const getProjectListPagination = (state, route) =>
  fromProject.getProjectListPagination(state.project, route);
export const getProjectToggleWishlistStatus = state => fromProject.getProjectToggleWishlistStatus(state.property)


//search
export const getSearchListingLoading = state => fromSearch.getSearchListingLoading(state.search);
export const getSearchListingItems = (state, route) => fromSearch.getSearchListingItems(state.search, route);
export const getSearchListingPagination = (state, route) =>
  fromSearch.getSearchListingPagination(state.search, route);
export const getSearchFilterData = (state, route) => fromSearch.getSearchFilterData(state.search, route);
export const getSearchSortData = (state, route) => fromSearch.getSearchSortData(state.search, route);
export const getSearchSuggestionItems = (state, route) =>
  fromSearch.getSearchSuggestionItems(state.search, route);
export const getSearchSuggestionLoading = state => fromSearch.getSearchSuggestionLoading(state.search);
export const getSearchCoordinateData = state => fromSearch.getSearchCoordinateData(state.search);

//news
export const getNewsListItems = state => fromNews.getNewsListItems(state.news);
export const getNewsListPagination = state => fromNews.getNewsListPagination(state.news);
export const getNewsListLoading = state => fromNews.getNewsListLoading(state.news);

//agent
export const getAgentAccountStatus = state => fromAgent.getAgentAccountStatus(state.agent);
export const getAgentAccountLoading = state => fromAgent.getAgentAccountLoading(state.agent);
export const getAgentAccountVerifyStatus = state => fromAgent.getAgentAccountVerifyStatus(state.agent);
export const getAgentAccountVerifyLoading = state => fromAgent.getAgentAccountVerifyLoading(state.agent);
export const getAgentSearchAgencyLoading = state => fromAgent.getAgentSearchAgencyLoading(state.agent);
export const getAgentSearchAgencyItems = state => fromAgent.getAgentSearchAgencyItems(state.agent);
export const getAgentSearchProjectLoading = state => fromAgent.getAgentSearchProjectLoading(state.agent);
export const getAgentSearchProjectItems = state => fromAgent.getAgentSearchProjectItems(state.agent);
export const getAgentPostAgentListingLoading = state =>
  fromAgent.getAgentPostAgentListingLoading(state.agent);

export const getAgentPostAgentListingStatus = state => fromAgent.getAgentPostAgentListingStatus(state.agent);

export const getAgentPostAgentListingItem = state => fromAgent.getAgentPostAgentListingItem(state.agent);
export const getAgentPostAgentListingItems = (state, id) =>
  fromAgent.getAgentPostAgentListingItems(state.agent, id);
// export const getAgentCurrentProjectID = state  => fromAgent.getAgentCurrentProjectID(state.agent)
export const getAgentPutAgentListingLoading = state => fromAgent.getAgentPutAgentListingLoading(state.agent);
export const getAgentListingLoading = state => fromAgent.getAgentListingLoading(state.agent);
export const getAgentListingItems = state => fromAgent.getAgentListingItems(state.agent);
export const getAgentPostListingLoadingv2 = state => fromAgent.getAgentPostListingLoadingv2(state.agent);
export const getAgentPostListingItemsv2 = state => fromAgent.getAgentPostListingItemsv2(state.agent);
export const getAgentPostListingStatusv2 = state => fromAgent.getAgentPostListingStatusv2(state.agent);

export const getAgentPostListingPaginationv2 = state => fromAgent.getAgentPostListingPaginationv2(state.agent);
export const getAgentListingPagination = state => fromAgent.getAgentListingPagination(state.agent);
export const getAgentListingDetailLoading = state => fromAgent.getAgentListingDetailLoading(state.agent);
export const getAgentCommonDataEnum = state => fromAgent.getAgentCommonDataEnum(state.agent);
export const getAgentPutAgentListingStatus = state => fromAgent.getAgentPutAgentListingStatus(state.agent);
export const getAgentListingImageLoading = state => fromAgent.getAgentListingImageLoading(state.agent);
export const getAgentListingImage = state => fromAgent.getAgentListingImage(state.agent);
export const getAgentDeleteListingImageLoading = state =>
  fromAgent.getAgentDeleteListingImageLoading(state.agent);
export const getAgentDeleteListingImageData = state => fromAgent.getAgentDeleteListingImageData(state.agent);
export const getAgentPostDetailPublishLoading = state =>
  fromAgent.getAgentPostDetailPublishLoading(state.agent);
export const getAgentPostDetailPublishStatus = state =>
  fromAgent.getAgentPostDetailPublishStatus(state.agent);
export const getAgentListImageDefaultLoading = state =>
  fromAgent.getAgentListImageDefaultLoading(state.agent);
export const getAgentListImageDefaultData = state => fromAgent.getAgentListImageDefaultData(state.agent);
export const getAgentListImageDefaultStatus = state => fromAgent.getAgentListImageDefaultStatus(state.agent);
export const getAgentSearchStateLoading = state => fromAgent.getAgentSearchStateLoading(state.agent);
export const getAgentDetailCategoryName = state => fromAgent.getAgentDetailCategoryName(state.agent);
export const getAgentSearchStateItems = state => fromAgent.getAgentSearchStateItems(state.agent);
export const getAgentSearchCityLoading = state => fromAgent.getAgentSearchCityLoading(state.agent);
export const getAgentSearchCityItems = state => fromAgent.getAgentSearchCityItems(state.agent);
export const getAgentProjectCreateLoading = state => fromAgent.getAgentProjectCreateLoading(state.agent);
export const getAgentProjectCreateStatus = state => fromAgent.getAgentProjectCreateStatus(state.agent);
export const getAgentProjectCreateItems = state => fromAgent.getAgentProjectCreateItems(state.agent);
export const getAgentLeadLoading = state => fromAgent.getAgentLeadLoading(state.agent)
export const getAgentLeadItems = state => fromAgent.getAgentLeadItems(state.agent)
export const getAgentLeadPagination = state => fromAgent.getAgentLeadPagination(state.agent)
export const getPutAgentLeadDetailLoading = state => fromAgent.getPutAgentLeadDetailLoading(state.agent)
export const getPutAgentLeadDetailStatus = state => fromAgent.getPutAgentLeadDetailStatus(state.agent)
export const getAgentCommonDataOptionsAgentLeadStatus = state => fromAgent.getAgentCommonDataOptionsAgentLeadStatus(state.agent)
export const getAgentCommonDataOptionsAgentSubscriptions = state => fromAgent.getAgentCommonDataOptionsAgentSubscriptions(state.agent)
export const getAgentCommonStateData = state => fromAgent.getAgentCommonStateData(state.agent)
export const getAgentCommonCityData = state => fromAgent.getAgentCommonCityData(state.agent)

export const getAgentSubscriptionLoading = state => fromAgent.getAgentSubscriptionLoading(state.agent);

export const getAgentSubscriptionStatus = state => fromAgent.getAgentSubscriptionStatus(state.agent);

export const getAgentSubscriptionItem = state => fromAgent.getAgentSubscriptionItem(state.agent);


//verification
export const getVerificationLoading = state => fromVerification.getVerificationLoading(state.verification);
export const getVerificationStatus = state => fromVerification.getVerificationStatus(state.verification);
export const getVerificationData = state => fromVerification.getVerificationData(state.verification);
export const getVerificationChangeEmailStatus = state => fromVerification.getVerificationChangeEmailStatus(state.verification)
export const getVerificationChangeEmailLoading = state => fromVerification.getVerificationChangeEmailLoading(state.verification)
export const getVerificationChangeEmailData = state => fromVerification.getVerificationChangeEmailData(state.verification)
export const getVerificationChangePhoneStatus = state => fromVerification.getVerificationChangePhoneStatus(state.verification)
export const getVerificationChangePhoneLoading = state => fromVerification.getVerificationChangePhoneLoading(state.verification)
export const getVerificationChangePhoneData = state => fromVerification.getVerificationChangePhoneData(state.verification)
//post
export const getPostListingLoading = state => fromPost.getPostListingLoading(state.post)
export const getPostListingItems = state => fromPost.getPostListingItems(state.post)
export const getPostListingPagination = state => fromPost.getPostListingPagination(state.post)
export const getPostCategoryType = state => fromPost.getPostCategoryType(state.post)

//wishlist
export const getWishlistItems = state => fromWishlist.getWishlistItems(state.wishlist)
export const getWishlistLoading = state => fromWishlist.getWishlistLoading(state.wishlist)
export const getWishlistPagination = state => fromWishlist.getWishlistPagination(state.wishlist)


export const getPropertyCommonDataFilter = state => fromCommon.getPropertyCommonDataFilter(state.common);


//Referral Code
export const getReferralCodeItems = state => fromProfile.getReferralCodeItems(state.profile)
export const getReferralCodePagination = state => fromProfile.getReferralCodePagination(state.profile)
export const getReferralCodeLoading = state => fromProfile.getReferralCodeLoading(state.profile)

//Transaction and statistics
export const getTransactionDataListingItems = state => fromListingTrans.getTransactionDataListingItems(state.listingTrans);
export const getTransactionCommonData = state => fromListingTrans.getTransactionCommonData(state.listingTrans);
export const getTransactionCommonTypeResidentialData = state => fromListingTrans.getTransactionCommonTypeResidentialData(state.listingTrans);
export const getTransactionCommonTypeCommericalData = state => fromListingTrans.getTransactionCommonTypeCommericalData(state.listingTrans);
export const getTransactionCommonTypeIndustrialData = state => fromListingTrans.getTransactionCommonTypeIndustrialData(state.listingTrans);
export const getTransactionCommonTenureData = state => fromListingTrans.getTransactionCommonTenureData(state.listingTrans);
export const getTransactionSuggestData = state => fromListingTrans.getTransactionSuggestData(state.listingTrans);
export const getTransactionSuggestDataLoading = state => fromListingTrans.getTransactionSuggestDataLoading(state.listingTrans);
export const getPageListingLoading = state => fromListingTrans.getPageListingLoading(state.listingTrans);
export const getPageListingItems = state => fromListingTrans.getPageListingItems(state.listingTrans);
export const getPageListingPagination = state => fromListingTrans.getPageListingPagination(state.listingTrans);




