import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { Color, Color as Colors } from '../../src/utils/Color'
import styled, { withTheme, useTheme } from "styled-components";
import { connect } from "dva";
import _ from "lodash";
import Router from "next/router";
import { NextSeo } from "next-seo";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomFont from "../../components/CustomFont";
import Divider from "@material-ui/core/Divider";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { maxDevice } from "../../src/utils/breakpoints";
import {
  JohorCoordinateData,
  KedahCoordinateData,
  KelantanCoordinateData,
  MelakaCoordinateData,
  PerakCoordinateData,
  PerlisCoordinateData,
  SabahCoordinateData,
  SelangorCoordinateData,
  KualaLumpurCoordinateData,
  NegeriSembilanCoordinateData,
  LabuanCoordinateData,
  PahangCoordinateData,
  PenangCoordinateData,
  PutrajayaCoordinateData,
  SarawakCoordinateData,
  TerengganuCoordinateData,
  PetalingJayaCoordinateData,
  JohorBahruCoordinateData,
  GeorgetownCoordinateData,
  TamanPelangiCoordinateData,
  SubangJayaCoordinateData,
  MontKiaraCoordinateData,
  IskandarPuteriCoordinateData,
  UptownCoordinateData,
  BukitJalilCoordinateData,
  BukitBintangCoordinateData,
  PuchongCoordinateData,
  AmpangCoordinateData,
  BangsarCoordinateData
} from '../../src/utils/CoordinateData'
import * as selectors from '../../src/selectors'
import * as searchActions from '../../src/actions/search'

const FooterStateContainer = styled.div`
  color: #2c2c2c;
  margin-top: 20px;
  @media ${maxDevice.sm} {
    margin: 10px 0px !important;
  }
  &:hover {
    cursor: pointer;
    color: ${Color.primaryColor};
    text-decoration: underline;
  }
`;
//============ Accordion Section CSS==================
const AccordionContainer = styled(Accordion)`
  background-color: ${Colors.textWhite} !important;

  &.MuiAccordion-root {
    box-shadow: none;
    // border-bottom: 1px solid ${Colors.primaryLightGrey} ;
    margin: 10px 0;
  }

  &.MuiAccordion-root::before {
    opacity: 0;
}
  &.Mui-expanded {
    margin: 2px 0px !important;
  }
`;
const AccordionSummaryContent = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    background: ${Colors.greyBackgroundColor};
  }
  && {
    .MuiAccordionSummary-content {
      margin: 20px 0 !important;
    }
  }
`;
const AccordionDetailsContent = styled(AccordionDetails)`
    // &.MuiAccordionDetails-root{
    //   background: ${Colors.greyBackgroundColor};
    // }
`;
//============ End of Accordian Section CSS==================

class FooterAccordionMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

 // onClickPropertyForSale = e => {
 //    const { clearFilterData } = props;
 //    e.preventDefault();
 //    clearFilterData && clearFilterData();
 //
 //    Router.push({
 //      pathname: "/property-for-sale"
 //    });
 //  };
 //
 //
 //  onClickPropertyForRent = e => {
 //    const { clearFilterData } = props;
 //    e.preventDefault();
 //    clearFilterData && clearFilterData();
 //
 //    Router.push({
 //      pathname: "/property-for-rent"
 //    });
 //  };

  onClickPropertyRentState = (e, coordinateData) => {
    const { setCoordinateData } = this.props;

    e.preventDefault();

    setCoordinateData && setCoordinateData(coordinateData);
    Router.push({
      pathname: "/property-for-rent"
    });
  };

  onClickPropertySaleState = (e, coordinateData) => {
    const { setCoordinateData } = this.props;

    e.preventDefault();

    setCoordinateData && setCoordinateData(coordinateData);

    Router.push({
      pathname: "/property-for-sale"
    });
    console.log(coordinateData)
  };

  render() {
    return (
      <div>
        <AccordionContainer>
          <AccordionSummaryContent
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <CustomFont styles={{ fontWeight: "500" }}>Top Searched Property For Sale</CustomFont>
          </AccordionSummaryContent>
          <AccordionDetailsContent>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Kuala Lumpur
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor/petaling-jaya`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Petaling Jaya
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/johor-bahru`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Johor Bahru
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor/subang-jaya`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Subang Jaya
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/mont-kiara`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Mont Kiara
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/iskandar-puteri`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Iskandar Puteri
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale?q=Uptown+Shah+Alam%2C+Kemuning+-+Shah+Alam+Highway%2C+Section+24%2C+Shah+Alam%2C+Selangor%2C+Malaysia`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Uptown
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/bukit-jalil`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Bukit Jalil
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/bukit-bintang`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Bukit Bintang
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/johor/pelangi`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Taman Pelangi
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang/george-town`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Georgetown
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor/puchong`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Puchong
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor/ampang`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Ampang
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur/bangsar`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Bangsar
                </CustomFont>
              </a>
            </FooterStateContainer>
          </AccordionDetailsContent>
        </AccordionContainer>
        {/*====2====*/}
        <AccordionContainer>
          <AccordionSummaryContent
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <CustomFont styles={{ fontWeight: "500" }}>Top Searched Property For Rent</CustomFont>
          </AccordionSummaryContent>
          <AccordionDetailsContent>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Kuala Lumpur
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor/petaling-jaya`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Petaling Jaya
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/johor-bahru`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Johor Bahru
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor/subang-jaya`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Subang Jaya
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/mont-kiara`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Mont Kiara
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/iskandar-puteri`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Iskandar Puteri
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent?q=Uptown+Shah+Alam%2C+Kemuning+-+Shah+Alam+Highway%2C+Section+24%2C+Shah+Alam%2C+Selangor%2C+Malaysia`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Uptown
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/bukit-jalil`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Bukit Jalil
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/bukit-bintang`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Bukit Bintang
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/johor/pelangi`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Taman Pelangi
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/penang/george-town`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Georgetown
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor/puchong`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Puchong
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor/ampang`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Ampang
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur/bangsar`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Bangsar
                </CustomFont>
              </a>
            </FooterStateContainer>
          </AccordionDetailsContent>
        </AccordionContainer>
        {/*=====3=====*/}
        <AccordionContainer>
          <AccordionSummaryContent
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <CustomFont styles={{ fontWeight: "500" }}>Malaysia Property For Sale</CustomFont>
          </AccordionSummaryContent>
          <AccordionDetailsContent>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/selangor`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Selangor
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/perak`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Perak
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/sabah`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Sabah
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/kelantan`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Kelantan
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/johor`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Johor
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/melaka`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Melaka
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/perlis`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Perlis
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/penang`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Penang
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/pahang`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Pahang
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/putrajaya`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Putrajaya
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/labuan`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Labuan
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/kuala-lumpur`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Kuala Lumpur
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/negeri-sembilan`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Negeri Sembilan
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/sarawak`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Sarawak
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/terengganu`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Terengganu
                </CustomFont>
              </a>
            </FooterStateContainer>
          </AccordionDetailsContent>
        </AccordionContainer>
        {/*=====4=====*/}
        <AccordionContainer>
          <AccordionSummaryContent
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <CustomFont styles={{ fontWeight: "500" }}>Malaysia Property For Rent</CustomFont>
          </AccordionSummaryContent>
          <AccordionDetailsContent>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/selangor`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Selangor
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/perak`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Perak
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/sabah`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Sabah
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/kelantan`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                  <CustomFont size="small" styles={{ fontWeight: "400" }}>
                  Properties For Rent in Kelantan
                  </CustomFont>
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/johor`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Johor
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/melaka`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Melaka
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/perlis`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Perlis
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/penang`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Penang
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/pahang`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Pahang
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-sale/putrajaya`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Sale in Putrajaya
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/labuan`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Labuan
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/kuala-lumpur`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Kuala Lumpur
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/negeri-sembilan`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Negeri Sembilan
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/sarawak`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Sarawak
                </CustomFont>
              </a>
            </FooterStateContainer>
            <FooterStateContainer>
              <a href={`${process.env.WEBSITE_URL}property-for-rent/terengganu`}>
                <CustomFont size="small" styles={{ fontWeight: "400" }}>
                Properties For Rent in Terengganu
                </CustomFont>
              </a>
            </FooterStateContainer>
          </AccordionDetailsContent>
        </AccordionContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authToken: selectors.getAuthToken(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearFilterData: () => dispatch(searchActions.clearFilterData()),
    setCoordinateData: coordinate => dispatch(searchActions.setCoordinateData(coordinate))
  };
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FooterAccordionMobile)
);
