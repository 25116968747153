import _ from "lodash";



//mobile prefix
export const getCommonDataMobilePrefix = state =>
  _.get(state, ["commonData", "mobile_prefix"], defaultMobilePrefix);

//gender
export const getCommonDataGender = state => _.get(state, ["commonData", "gender"]);

//role
export const getCommonDataRole = state => _.get(state, ["commonData", "roles"]);

//blogStatus
export const getCommonDataBlogStatus = state => _.get(state, ["commonData", "blog_status"]);

//attributes
export const getCommonDataAttributes = state => _.get(state, ["commonData", "attributes"]);

//statistic
export const getCommonAttributeTodayNewBlogCount = attributes => _.get(attributes, ["today_blog_posted"], 0);
export const getCommonAttributeTodayNewEventCount = attributes =>
  _.get(attributes, ["today_event_posted"], 0);

//insider-guide status
export const getCommonBlogStatus = state => _.get(state, ["commonData", "blog_status"], null);

//wallet
export const getCommonWalletRequestStatus = state =>
  _.get(state, ["commonData", "wallet_request_status"], null);

export const getCommonDataOptionsPostCategory = state =>
  _.get(state, ["commonData", "options", "postCategory"], []);

//phone
export const getMobilePrefixKey = mobilePrefix => _.get(mobilePrefix, ["key"], null);
export const getMobilePrefixValue = mobilePrefix => _.get(mobilePrefix, ["value"], null);

// category data && tag data
export const getCommonCategoryData = state => _.get(state, ["categoryData"], null);
export const getCommonTagData = state => _.get(state, ["tagData"], null);
export const getCommonTopUpData = state => _.get(state, ["topUpData"], null);
export const getCommonTopUpLoading = state => _.get(state, ["topUp", "loading"], false);
export const getCommonOptionsCountry = state => _.get(state, ["commonData", "options", "countryCode"], []);
export const getCommonOptionsStateCode = state => _.get(state, ["commonData", "options", "stateCode"], []);
export const getCommonFaqLoading = state => _.get(state, ["faq", "loading"], false);
export const getCommonFaqData = state => _.get(state, ["faq", "data"], null);
const defaultMobilePrefix = [
  {
    value: "+60",
    name: "+60 Malaysia"
  }
];

export const getTopUpPageTitle = topUp => _.get(topUp, ["title"], "");
export const getTopUpPageContent = topUp => _.get(topUp, ["content"], "");
export const getCommonDataFilter = state => _.get(state, ["commonData", "filter"], null);
export const getCommonDataEnum = state => _.get(state,['commonData','enum'],null)
export const getCommonDataOptions = state => _.get(state,['commonData','options'],null)
export const getCommonDataCity = state => _.get(state,['commonData','city'],null)
export const getCommonDataState = state => _.get(state,['commonData','state'],null)
export const getCommonDataSort = state => _.get(state, ["commonData", "sort", "listing"], []);
export const getCommonOptionsUserPropertyStatus = state => _.get(state,['commonData','options','userPropertyStatus'],[])
export const getCommonDataLoading = state => _.get(state,['commonDataLoading'],false);
export const getCommonDataCityOfState = state => _.get(state,['state', 'name'],"")

//filter
export const getFilterCategoryResidentialItems = filter =>
  _.get(filter, ["category", "residential", "items"], null);
export const getFilterCategoryCommercialItems = filter =>
  _.get(filter, ["category", "commercial", "items"], null);
export const getFilterCategoryAgriculturalItems = filter =>
    _.get(filter, ["category", "agricultural", "items"], null);
export const getFilterCategoryIndustrialItems = filter =>
    _.get(filter, ["category", "industrial", "items"], null);
export const getFilterCategoryOthersItems = filter =>
    _.get(filter, ["category", "others", "items"], null);



export const getFilterTenureItems = filter => _.get(filter, ["tenure", "items"], null);
export const getFilterFloorLevelItems = filter => _.get(filter, ["floorLevel", "items"], null);
export const getFilterFurnishingItems = filter => _.get(filter, ["furnishing", "items"], null);
export const getFilterBathRoomItems = filter => _.get(filter, ["bathRoom", "items"], null);
export const getFilterPetPolicyItems = filter => _.get(filter, ["petPolicy", "items"], null);
export const getFilterBedRoomItems = filter => _.get(filter, ["bedRoom", "items"], null);

//filterData
export const getFilterDataValue = filterData => _.get(filterData, ["value"], "");
export const getFilterDataName = filterData => _.get(filterData, ["name"], "");
export const getFilterDataParent = filterData => _.get(filterData, ["parent"], "");
export const getFilterDataItems = filterData => _.get(filterData, ["items"], []);


//faq
export const getFaqDetailAnswer = faq => _.get(faq,['answer'],"")
export const getFaqDetailQuestion = faq => _.get(faq,['question'],"")


//Rent/Buy/Projects Filter
export const getPropertyCommonDataFilter = state =>
  _.get(state, ['commonData', 'filter'], null);

export const getFilterCategoryCommonResidentialItems = filter =>
  _.get(filter, ["category", "residential", "items"], null);
export const getFilterCategoryCommonCommercialItems = filter =>
  _.get(filter, ["category", "commercial", "items"], null);
export const getFilterCategoryCommonAgriculturalItems = filter =>
  _.get(filter, ["category", "agricultural", "items"], null);
export const getFilterCategoryCommonIndustrialItems = filter =>
  _.get(filter, ["category", "industrial", "items"], null);
export const getFilterCategoryCommonOthersItems = filter =>
    _.get(filter, ["category", "others", "items"], null);


export const getCommonOptionFloorSizeItem = common => _.get(common, ["floorSize"], []);
export const getCommonOptionLandSizeItem = common => _.get(common, ["landSize"], []);


