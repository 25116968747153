import React, { Component } from "react";
import styled from "styled-components";
import { withTheme } from "styled-components";
import Link from "next/link";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "../../styles/jss/nextjs-material-kit/components/headerStyle.js";
import Image from "next/image";
import Box from "@mui/material/Box";
import CustomFont from "../../components/CustomFont";
import { Color } from "../../src/utils/Color";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@material-ui/core/Container";
import Router from "next/router";
import * as selectors from "../../src/selectors";
import * as authActions from "../../src/actions/auth";
import { connect } from "dva";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { getAuth, signOut } from "firebase/auth";
import _ from "lodash";
const StyledAppBarContainer = styled(AppBar)`
  display: flex;
  border: 0;
  border-radius: 3px;
  padding: 0.25rem 0;
  color: #2c2c2c;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.15);
  transition: all 150ms ease 0s;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: relative;
  z-index: unset;
  height: 73px;

  .transparent {
    background-color: transparent !important;
    box-shadow: none;
    padding-top: 25px;
    color: #ffffff;
  }

  .white {
    border: 0;
    padding: 0.25rem 0;
    margin-bottom: 20px;
    color: #2c2c2c;
    background-color: #fff !important;
    box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
      0 7px 10px -5px rgba(0, 0, 0, 0.15);
  }

  &.MuiAppBar-root{
    padding-right: 0 !important;
  }
`;

const RespondsiveContainer = styled.div`
  //margin: 0 16px;
  margin: 0 20px;
  margin-bottom: 120px;
`;

const ToolBarContainer = styled(Toolbar)`
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  min-height: 50px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-wrap: nowrap;
`;

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
`;

const HeaderImage = styled(Image)`
  height: 100%;
  width: 200px !important;
  object-fit: contain;
  position: relative;
`;

const DrawerHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.3rem;
  height: 64px;
  width: 100%;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoTransparent: props.color === "transparent",
      mobileOpen: false,
      activeColor: "",
      logoutDialogVisible: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
    return function cleanup() {
      if (this.props.changeColorOnScroll) {
        window.removeEventListener("scroll", this.headerColorChange);
      }
    };
  }

  headerColorChange = () => {
    const { color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      this.setState({ logoTransparent: false });
    } else {
      this.setState({ logoTransparent: true });
    }
  };

  setLogo = () => {};

  handleDrawerToggle = () => {
    this.setState({
      mobileOpen: !this.state.mobileOpen
    });
  };

  onClickLogin = () => {
    Router.push(`/login`);
  };

  onClickHome = () => {
    Router.push(`/`);

  }

  onClickLogout = () => {
    this.onOpenDialog();
  };

  onOpenDialog = () => {
    this.setState({ logoutDialogVisible: true });
  };

  onCloseDialog = () => {
    this.setState({
      logoutDialogVisible: false
    });
  };

  onPressLogout = () => {
    const { logoutRequest } = this.props;
    this.onCloseDialog();
    logoutRequest && logoutRequest();
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        // An error happened.
      });
  };

  render() {
    const { logoTransparent, logoutDialogVisible } = this.state;
    const {
      color,
      rightLinks,
      leftLinks,
      brand,
      fixed,
      absolute,
      style,
      authToken
    } = this.props;
    const brandComponent = (
      <div style={{ height: "54px", display: "flex", alignItems: "center" }}>
        <Hidden mdDown>
          <div style={{ cursor: "pointer" }}>
            <a
              href={`${process.env.WEBSITE_URL}`}
              onClick={this.onClickHome}
              style={{ width: "100%", display: "block", height: "54px" }}
            >

              <Image
                // src={
                //   logoTransparent && color === "transparent"
                //     ? "/images/Logo/pg-logo-main.png"
                //     : "/images/Logo/pg-logo-main.png"
                // }
                src={
                  logoTransparent && color === "transparent"
                    ? "/images/Logo/pg-logo-main-r.png"
                    : "/images/Logo/pg-logo-main-r.png"
                }
                height={54}
                objectFit="contain"
                width={250}
                alt="Property Genie"
              />

            </a>
          </div>
        </Hidden>
        <Hidden lgUp>
          <a
            style={{ display:"flex" }}
            href={`${process.env.WEBSITE_URL}`}
            onClick={this.onClickHome}
          >
            <Image
              // src={
              //   logoTransparent && color === "transparent"
              //     // ? "/images/Logo/pg-logo-mobile.png"
              //     // : "/images/Logo/pg-logo-mobile.png"
              //       ? "/images/Logo/pg-logo-main.png"
              //     : "/images/Logo/pg-logo-main.png"
              // }
                src={
                  logoTransparent && color === "transparent"
                      ? "/images/Logo/pg-logo-main-r.png"
                      : "/images/Logo/pg-logo-main-r.png"
                }
              height={60}
              width={110}
              objectFit="contain"
              alt="Property Genie"
            />
          </a>
        </Hidden>
      </div>
    );

    const styles = { ...style, color: "#2c2c2c", backgroundColor: "white" ,paddingRight:"0px !important"};
    return (
      <StyledAppBarContainer
        color="transparent"
        style={
          absolute
            ? { position: "absolute", zIndex: 1100, ...styles }
            : fixed
            ? { position: "fixed", ...styles }
            : { ...styles }
        }
      >
        <Container>
          <ToolBarContainer>
            <Hidden lgUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
            {leftLinks !== undefined ? brandComponent : null}

            {leftLinks !== undefined ? (
              <Hidden mdDown>{leftLinks}</Hidden>
            ) : (
              brandComponent
            )}

            <Hidden mdDown>{rightLinks}</Hidden>
            <Hidden lgUp>
              <div style={{ marginRight: "2.5rem" }} />
              {/*{!_.isEmpty(authToken) ? (*/}
              {/*  <div onClick={this.onClickLogout}>*/}
              {/*    <CustomFont*/}
              {/*      hover*/}
              {/*      styles={{*/}
              {/*        color: logoTransparent ? "inherit" : Color.primaryRed*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      Logout*/}
              {/*    </CustomFont>*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  <div onClick={this.onClickLogin}>*/}
              {/*    <CustomFont*/}
              {/*      hover*/}
              {/*      styles={{*/}
              {/*        color: logoTransparent ? "inherit" : Color.primaryColor*/}
              {/*      }}*/}
              {/*    >*/}
              {/*     Login*/}
              {/*    </CustomFont>*/}
              {/*  </div>*/}
              {/*)}*/}
            </Hidden>
          </ToolBarContainer>
        </Container>
        <Hidden lgUp>
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={this.state.mobileOpen}
            PaperProps={{
              style: {
                border: "none",
                bottom: "0",
                transitionProperty: "top, bottom, width",
                transitionDuration: ".2s, .2s, .35s",
                transitionTimingFunction: "linear, linear, ease",
                width: "100vw",
                boxShadow:
                  "0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                position: "fixed",
                display: "block",
                top: "0",
                height: "100vh",
                right: "0",
                left: "auto",
                visibility: "visible",
                overflowY: "visible",
                borderTop: "none",
                textAlign: "left",
                paddingRight: "0px !important",
                paddingLeft: "0",
                transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
                backgroundColor: Color.primaryColor,
                overflow: "scroll"
              }
            }}
            onClose={this.handleDrawerToggle}
          >
            <RespondsiveContainer>
              <DrawerHeaderContainer>
                <FlexContainer>
                  <IconButton onClick={this.handleDrawerToggle}>
                    <CloseIcon style={{ color: "white", fontSize: "2rem" }} />
                  </IconButton>
                </FlexContainer>
                <FlexContainer style={{ justifyContent:"center" }}>
                  <a
                    style={{ display:"flex" }}
                    href={`${process.env.WEBSITE_URL}`}
                    onClick={this.onClickHome}
                  >
                    <Image src="/images/Logo/pg-logo-white-r.png" height={63} width={170} alt="Property Genie"/>
                  </a>
                </FlexContainer>
                <FlexContainer />
              </DrawerHeaderContainer>
              {leftLinks}
              {rightLinks}
            </RespondsiveContainer>
          </Drawer>
        </Hidden>
        <Dialog
          open={logoutDialogVisible}
          onClose={this.onCloseDialog}
          // PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle
            style={{ cursor: "move", fontWeight: "600", color:Color.primaryColor }}
            id="draggable-dialog-title"
          >
            Sign Out
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ color: "#2c2c2c" }}>
              Are you sure to sign out?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCloseDialog}>No</Button>
            <Button onClick={this.onPressLogout}>Yes</Button>
          </DialogActions>
        </Dialog>
      </StyledAppBarContainer>
    );
  }
}

Header.defaultProps = {
  theme: {
    main: "#2c2c2c"
  }
};

function mapStateToProps(state) {
  return {
    authToken: selectors.getAuthToken(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logoutRequest: () => dispatch(authActions.requestLogout())
  };
}

export default withTheme(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
